import { HttpClient, baseApiPath } from '@uplink/shared';
import { observable, action } from 'mobx';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { AlertStore } from '@uvgo-shared/alert';
import { ServiceItemPricingModel, VendorLocationModel } from '../Modules/Shared/Models';
import { Logger } from '@wings-shared/security';
import { IAPIGridRequest, IAPIPageResponse, Utilities } from '@wings-shared/core';
import { vendorManagementHeaders } from './Base.store';
import { apiUrls } from './API.url';
import { IAPIVMSVendorComparison } from '../Modules/Shared/Interfaces';
import { ServiceItemPricingLocationModel } from '../Modules/Shared/Models/ServiceItemPricingLocation.model';

export class ServiceItemPricingStore {
  @observable test: string = '';
  @observable isDirectService: boolean = true;
  @observable isCommentFieldRequired: boolean = false;
  @observable vendorLocationData: VendorLocationModel = new VendorLocationModel();
  @observable commentValue: string = '';
  @observable isAnyColHidden: boolean = false

  @action
  public getVMSComparison(pageRequest?: IAPIGridRequest): Observable<IAPIPageResponse<ServiceItemPricingModel>> {
    const http: HttpClient = new HttpClient({
      baseURL: baseApiPath.vendorManagementNoSqlUrl,
      headers: vendorManagementHeaders,
    });
    const params: string = Utilities.buildParamString({
      CollectionName: 'ServiceItemPricing',
      ...pageRequest,
    });
    return http.get<IAPIPageResponse<IAPIVMSVendorComparison>>(`/api/vendormanagement?${params}`).pipe(
      Logger.observableCatchError,
      map(response => ({ ...response, results: ServiceItemPricingModel.deserializeList(response.results) }))
    );
  }

  @action
  public upsertServiceItemPricing(payload: ServiceItemPricingModel[]): Observable<ServiceItemPricingModel> {
    const http = new HttpClient({ headers: vendorManagementHeaders });
    payload[0].id = payload[0].id === null ? 0 : payload[0].id;
    const isNewRequest: boolean = !(payload[0].id != null && payload[0].id !== 0);
    const upsertRequest: Observable<ServiceItemPricingModel> = isNewRequest
      ? http.post<any>(`${baseApiPath.vendorManagementCoreUrl}/${apiUrls.serviceItemPricing}`, payload)
      : http.put<any>(`${baseApiPath.vendorManagementCoreUrl}/${apiUrls.serviceItemPricing}`, payload);
    return upsertRequest.pipe(
      Logger.observableCatchError,
      tap(() => AlertStore.info('Pricing saved successfully!'))
    );
  }

  @action
  public upsertServiceItemPricingLocations(payload: ServiceItemPricingLocationModel[]): 
    Observable<ServiceItemPricingLocationModel> {
    const http = new HttpClient({ headers:vendorManagementHeaders });
    payload[0].id=payload[0].id===null?0:payload[0].id;
    const isNewRequest: boolean = !(payload[0].id!=null && payload[0].id !== 0);
    const upsertRequest: Observable<ServiceItemPricingLocationModel> = isNewRequest
      ? http.post<any>(`${baseApiPath.vendorManagementCoreUrl}/${apiUrls.serviceItemPricing}`, payload)
      : http.put<any>(`${baseApiPath.vendorManagementCoreUrl}/${apiUrls.serviceItemPricing}`, payload);
    return upsertRequest.pipe(
      Logger.observableCatchError,
      tap(() => AlertStore.info('Pricing saved successfully!'))
    );
  }

  @action
  public removePricing(serviceItemPricingId: number[]): Observable<string> {
    const payload = {
      userId: 'string',
      serviceItemPricingId,
    };
    const http = new HttpClient({ headers: vendorManagementHeaders });
    return http.delete<any>(`${baseApiPath.vendorManagementCoreUrl}/${apiUrls.serviceItemPricing}`, payload).pipe(
      Logger.observableCatchError,
      map((response: any) => response),
      tap(() => AlertStore.info('Pricing deleted successfully!'))
    );
  }

  @action
  public sendPricingDataUpdateEmailNotification(locationId:number): Observable<boolean>
  {
    const http = new HttpClient({ headers: vendorManagementHeaders });
    return http.get<any>(`${baseApiPath.vendorManagementCoreUrl}/${apiUrls.emailNotification.sendPricingDataUpdateEmailNotification}/${locationId}`).pipe(
      Logger.observableCatchError,
      map((response: any) => response)
    );
  }
}
