import React, { FC, useEffect } from 'react';
import { IClasses } from '@wings-shared/core';
import { inject, observer } from 'mobx-react';
import { Box, FormControlLabel, Radio, RadioGroup, withStyles } from '@material-ui/core';
import { styles } from '../../../OperationalInformation/Handler/OperationalHours/OperationalHours.styles';

interface Props {
  classes?: IClasses;
  onRadioChange;
  divShowHide: boolean;
  radioGroupData;
}

const AccordionRadioGroups: FC<Props> = ({
  classes,
  onRadioChange,
  divShowHide,
  radioGroupData,
}) => {

  useEffect(() => {
    
  }, []);

  const checkQuarantineHours = (event) => {
    const value = event.target.value;
    divShowHide && onRadioChange(value);
  };
  
  return (
    
    <div className='accordionRadioGroupsWrapper'>
      <Box>
        <RadioGroup 
          row 
          aria-label="quarantineHours" 
          name="quarantineHours" 
          defaultValue="" 
          className='radioGroupWrapper'
          onChange={checkQuarantineHours}
        >
          {
            radioGroupData.map((item, index) => (
              <FormControlLabel
                value={item?.value}
                control={<Radio color="primary" />}
                label={item.label}
              />  
            ))}
        </RadioGroup>
      </Box>
    </div>
  );
};

export default inject(
  
)(withStyles(styles)(observer(AccordionRadioGroups)));