import React, { FC, ReactNode, useEffect, useRef, useState } from 'react';
import {
  IAPIGridRequest,
  IAPIPageResponse,
  IClasses,
  SearchStore,
  UIStore,
} from '@wings-shared/core';
import { useUnsubscribe } from '@wings-shared/hooks';
import { finalize, takeUntil } from 'rxjs/operators';
import { inject, observer } from 'mobx-react';
import { useNavigate } from 'react-router';
import { forkJoin } from 'rxjs';
import { ModalStore } from '@uvgo-shared/modal-keeper';
import {
  useAgGrid,
  useGridState,
} from '@uplink-shared/custom-ag-grid';
import { BaseStore, VendorUserStore } from '../../../Stores';
import { SidebarStore } from '@uplink-shared/layout';
import { styles } from './VendorUser.style';
import {
  VendorManagmentModel,
} from '../../Shared';
import { AuthStore } from '@uplink-shared/security';
import CustomHeader from '../../Shared/Components/CustomHeader/CustomHeader';
import { gridFilters } from './Fields';
import { sidebarMenus } from '../../Shared/Components/SidebarMenu/SidebarMenu';
import { ISearchHeaderRef, SearchHeaderV2 } from '@uplink-shared/form-controls';
import { withStyles , CircularProgress } from '@material-ui/core';
import { COLLECTION_NAMES } from '../../Shared/Enums/CollectionName.enum';
import { VENDOR_DOCUMENT_DATA_FILTER } from '../../Shared/Enums/VendorDocument.enum';
import CustomButton from '../../Shared/Components/CustomButton/CustomButton';
import InfiniteScroll from '../../Shared/Components/InfiniteScroll/InfiniteScroll';
import CustomList from '../../Shared/Components/CustomList/CustomList';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import CustomDialog from '../../Shared/Components/CustomDialog/CustomDialog';
import CustomTooltip from '../../Shared/Components/CustomTooltip/CustomTooltip';
import { MixPanelTrackingEvents } from '@uplink/shared';
import { AnalyticsStore } from '@uplink-shared/analytics';
import { IAPIResponseVendorUser } from '../../Shared/Interfaces/Response/API-Response-VendorUser';
import { VendorUserModel } from '../../Shared/Models/VendorUser.model';

interface Props {
  vendorUserStore: VendorUserStore;
  classes: IClasses;
}

const VendorUser: FC<Props> = ({ 
  vendorUserStore, 
  classes, 
}) => {
  const unsubscribe = useUnsubscribe();
  const navigate = useNavigate();
  const searchHeaderRef = useRef<ISearchHeaderRef>();
  const gridState = useGridState();
  const agGrid = useAgGrid<VENDOR_DOCUMENT_DATA_FILTER, VendorUserModel>(gridFilters, gridState);

  useEffect(() => {
    AnalyticsStore.track(MixPanelTrackingEvents.VENDOR_USER);
    SidebarStore.setNavLinks(sidebarMenus(), 'vendor');
    vendorUserStore.hasDataLoaded = true;
  }, []);

  const loadInitialData = (pageRequest?: IAPIGridRequest) => {
    UIStore.setPageLoader(true);
    gridState.setIsProcessing(true);
    const request: IAPIGridRequest = {
      pageNumber: gridState.pagination.pageNumber,
      pageSize: gridState.pagination.pageSize,
      ...searchCollection(),
      ...agGrid.filtersApi.gridSortFilters(),
      ...pageRequest,
      filterCollection: JSON.stringify([
        {
          propertyName: 'Vendor.Id',
          propertyValue: AuthStore?.vendorProfile?.id,
        },
        {
          propertyName: 'Status.Id',
          propertyValue: 2,
          filterType: 'ne'
        }
      ]),
    };
    forkJoin([ vendorUserStore.getVendorUser(COLLECTION_NAMES.VENDOR_USER, request) ])
      .pipe(
        takeUntil(unsubscribe.destroy$),
        finalize(() => {
          UIStore.setPageLoader(false);
          gridState.setIsProcessing(false);
        })
      )
      .subscribe((response: [IAPIPageResponse<IAPIResponseVendorUser>]) => {
        const results = VendorUserModel.deserializeList(response[0].results);
        if (results.length === 0) {
          vendorUserStore.hasDataLoaded = false;
          return;
        }
        if (response[0].pageNumber === 1) {
          gridState.setGridData([]);
        }
        gridState.setGridData([ ...gridState.data, ...results ]);
      });
  };

  const colDefNew = [
    {
      headerName: '',
      field: 'givenName',
    },
    {
      headerName: '',
      field: 'surName',
    },
    {
      headerName: 'Email Address',
      field: 'email',
    },
    {
      headerName: 'User Role',
      field: 'userRole.name',
    },
    {
      headerName: 'Location',
      field: 'vendorUserLocation',
      
    },
    {
      headerName: 'Status',
      field: 'status.name',
    }

  ];

 
  const rightContent = (): ReactNode => {
    return (
      <div className={classes.buttonContainer}>
        <CustomButton variant="contained"  startIcon={<AddRoundedIcon />} 
          to="/vendor/user/upsert" title="Add User" />
      </div>
    );
  };

  const searchCollection = (): IAPIGridRequest | null => {
    const propertyValue = getSearchValue();
    vendorUserStore.hasDataLoaded = true
    if (propertyValue === '') {
      return null;
    }
    const filters = [
      {
        propertyName: 'Email',
        propertyValue: propertyValue,
        filterType: 'string',
      },
      {
        propertyName: 'GivenName',
        propertyValue: propertyValue,
        operator: 'or',
        filterType: 'string',
      },
      {
        propertyName: 'SurName',
        propertyValue: propertyValue,
        operator: 'or',
        filterType: 'string',
      },
      {
        propertyName: 'VendorLocation.Name',
        propertyValue: propertyValue,
        operator: 'or',
        filterType: 'string',
      },
      {
        propertyName: 'UserRole',
        propertyValue: propertyValue,
        operator: 'or',
        filterType: 'string',
      },
      {
        propertyName: 'Status.Name',
        propertyValue: propertyValue,
        operator: 'or',
        filterType: 'string',
      },
    ];
    return {
      searchCollection: JSON.stringify(filters),
    };
  };

  const deleteUser = (model:VendorUserModel): void => {
    gridState.setIsProcessing(true);
    UIStore.setPageLoader(true);
    vendorUserStore
      ?.upsertVendorUser(model.serialize(
        model.id,
        model.userRole.name?.name,
        model.oktaUserId,2))
      .pipe(
        takeUntil(unsubscribe.destroy$),
        finalize(() => {
          UIStore.setPageLoader(false);
          gridState.setIsProcessing(false);
          gridState.setIsAllRowsSelected(false);
        })
      )
      .subscribe({
        next: (response: VendorUserModel) => {
          gridState.setGridData([]);
          loadInitialData({ pageNumber: 1 });
        },
        error: error => {
          BaseStore.showAlert(error.message, model.id);
        },
      });
  };

  const getConfirmation = (item:VendorUserModel): void => {
    ModalStore.open(
      <CustomDialog
        title="Delete Data"
        message={'Are you sure you want to delete this data?'}
        yesButton="Confirm"
        noButton="Cancel"
        onNoClick={() => ModalStore.close()}
        onYesClick={() => deleteUser(item)}
        className={classes.vendorUserConfirmationModal}
      />
    );
  };

  const getSearchValue = (): string => {
    const searchHeader = searchHeaderRef.current?.getFilters();
    if (!searchHeader) {
      return null;
    }
    return searchHeader.searchValue || '';
  };

  const setSearchData = (): void => {
    const searchHeaderFilter = searchHeaderRef.current?.getFilters();
    SearchStore.searchData.set(location.pathname, {
      searchValue: searchHeaderFilter?.searchValue,
      selectInputsValues: searchHeaderFilter?.selectInputsValues,
      chipValue: searchHeaderFilter.chipValue,
      pagination: gridState.pagination,
    });
  };

  const onEdit = item => {
    setSearchData();
    navigate(`upsert/${item?.id}`);
  };
 
  return (
    <>
      <CustomHeader title={<CustomTooltip title={`${AuthStore.vendorProfile.name} User`}/>} />
      <SearchHeaderV2
        placeHolder="Start typing to search"
        ref={searchHeaderRef}
        onExpandCollapse={agGrid.autoSizeColumns}
        selectInputs={[]}
        onResetFilterClick={() => {
          gridState.setGridData([]);
          loadInitialData({ pageNumber: 1 });
        }}
        rightContent={rightContent}
        onFilterChange={isInitEvent => {
          gridState.setGridData([]);
          vendorUserStore.hasDataLoaded = true;
          loadInitialData({ pageNumber: isInitEvent ? gridState.pagination.pageNumber : 1 });
        }}
        isLoading={UIStore.pageLoading}
      />
      <InfiniteScroll
        pageStart={0}
        loadMore={page => {
          const searchData = SearchStore.searchData.get(location.pathname);
          if (searchData) {
            searchHeaderRef.current?.setupDefaultFilters(searchData);
            SearchStore.clearSearchData(location.pathname);
          }
          setTimeout(() => {
            loadInitialData({ pageNumber: page });
          }, 200);
        }}
        hasMore={vendorUserStore.hasDataLoaded && !UIStore.pageLoading}
        loader={
          <div style={{ textAlign: 'center' }}>
            <CircularProgress />
          </div>
        }
        useWindow={false}
      >
        <CustomList
          classes={classes}
          colDef={colDefNew}
          rowData={gridState.data}
          isHeaderVisible={true}
          onEdit={item => onEdit(item)}
          onDelete={item => getConfirmation(item)}
          showDeleteButton={true}
          isVendorUser={true}
          isLoading={UIStore.pageLoading}
        />
      </InfiniteScroll>
    </>
  );
};
export default inject(
  'vendorUserStore',
)(withStyles(styles)(observer(VendorUser)));
