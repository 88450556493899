import { action, observable } from 'mobx';
import { apiUrls } from '../API.url';
import { map } from 'rxjs/operators';
import { Logger } from '@uplink-shared/security';
import { baseApiPath, HttpClient } from '@uplink/shared';
import { Observable } from 'rxjs';
import { vendorManagementHeaders } from '../Base.store';
import { IAPIPageResponse, tapWithAction } from '@wings-shared/core';
import { VendorOnBoardSlideThree } from 
  '../../Modules/Shared/Models/VendorLocationOnBoarding/VendorOnBoardSlideThree.model';
import { OperationTypeDocumentList } from 
  '../../Modules/Shared/Models/VendorLocationOnBoarding/OperationTypeDocumentList.model';

export class SlideThreeStore {
  @observable public newVendorDocuments: OperationTypeDocumentList[] = [];

  @action
  public getDocumentsByOperationTypeId(operationTypeId: number): Observable<IAPIPageResponse<VendorOnBoardSlideThree>> {
    const http: HttpClient = new HttpClient({ headers: vendorManagementHeaders });
    return http
      .get<any>(`${baseApiPath.vendorManagementCoreUrl}/${apiUrls.vendorOnBoardSlideThree}/${operationTypeId}`)
      .pipe(
        Logger.observableCatchError,
        map(response => ({ ...response, results: VendorOnBoardSlideThree.deserialize(response) })),
        tapWithAction(
          response =>
            (this.newVendorDocuments = OperationTypeDocumentList.deserializeList(response.operationTypeDocumentLists))
        )
      );
  }
}
