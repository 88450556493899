import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    height: '100%',
    alignItems: 'center',
    '& .greenColor': {
      borderRadius: '100px',
      color: '#65A61B !important',
      backgroundColor: 'rgba(101,166,27, 0.15)',
    },
    '& .greyColor': {
      borderRadius: '100px',
      color: '#5F5F5F !important',
      backgroundColor: 'rgba(95,95,95, 0.15)',
    },
  },
}));
