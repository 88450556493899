import React from 'react';
import { INavigationLink, RootDataStore } from '@uplink-shared/layout';
import StoreOutlinedIcon from '@material-ui/icons/StoreOutlined';
import {
  AddressBookIcon,
  PhoneIcon,
  LocationIcon,
  TfoSidebarGeneralInfoIcon,
  MenuListIcon,
  InfoIcon,
  UserIconDarkIcon,
  PriceTagIcon, 
  HandlerIcon,
  AdditionalInfoIcon
} from '@uvgo-shared/icons';
import DescriptionIcon from '@material-ui/icons/Description';

export const sidebarMenus = (): INavigationLink[] => [
  {
    to: '/vendor',
    title: 'Vendor',
    icon: <StoreOutlinedIcon />,
    expandedMenuBlock: 'panel1',
    subMenuItem: [
      {
        to: '/vendor',
        title: 'General Information',
        icon: <StoreOutlinedIcon />,
      },
      {
        to: '/vendor/contact',
        title: 'Contacts',
        icon: <PhoneIcon />,
      },
      {
        to: '/vendor/addresses',
        title: 'Addresses',
        icon: <AddressBookIcon />,
      },
      {
        to: '/vendor/documents',
        title: 'Documents',
        icon: <DescriptionIcon />,
      },
    ],
  },
  {
    to: '/vendor/locations',
    title: 'Location',
    expandedMenuBlock: 'panel2',
    icon: <LocationIcon />,
    subMenuItem: [
      {
        to: '/vendor/locations',
        title: 'Location List',
        icon: <MenuListIcon />,
      },
      {
        to: '/vendor/locations/general-information',
        title: 'General Information',
        icon: <TfoSidebarGeneralInfoIcon />,
        isDisabled: !RootDataStore.locationData.isLocationSet,
      },
      {
        to: '/vendor/locations/addresses',
        title: 'Addresses',
        icon: <AddressBookIcon />,
        isDisabled: !RootDataStore.locationData.locationId,
      },
      {
        to: '/vendor/location/contact',
        title: 'Contacts',
        icon: <PhoneIcon />,
        isDisabled: !RootDataStore.locationData.locationId,
      },
      {
        to: '/vendor/location/documents',
        title: 'Documents',
        icon: <DescriptionIcon />,
        isDisabled: !RootDataStore.locationData.locationId,
      },
      {
        to: '/vendor/location/operational-essential',
        title: 'Operational Essentials',
        icon: <TfoSidebarGeneralInfoIcon />,
        isDisabled: !RootDataStore.locationData.locationId,
      }, 
      {
        to: '/vendor/location/pricing',
        title: 'Pricing',
        icon: <PriceTagIcon />,
        isDisabled: !RootDataStore.locationData.locationId,
      }, 
      {
        to: '/vendor/location/operational-information/aircraft-logistics',
        title: 'Operational Information',
        icon: <AdditionalInfoIcon />,
        isDisabled: !RootDataStore.locationData.locationId,
      },      
    ],
  }
];

export const newVendorOnboarding = (): INavigationLink[] => [
  {
    to: '/vendor/new-vendor-information',
    title: 'New Vendor Information',
    expandedMenuBlock: 'panel3',
    icon: <AdditionalInfoIcon />,
    subMenuItem: [
      {
        to: '/vendor/new-vendor-information',
        title: 'New Vendor Information',
      }
    ]
  }
];
