export enum SETTING_CATEGORIES {
  VENDORS = 1,
  VENDOR_LOCATIONS,
  VENDOR_PRICING,
  VENDOR_PRICING_SETTINGS,
  VENDOR_CONTACTS,
  VENDOR_ADDRESS,
  VENDOR_DOCUMENT,
  VENDOR_OPERATIONAL_ESSENTIAL
}

export enum SETTING_ID {
  STATUS = 1,
  LOCATION_TYPE,
  LOCATION_LEVEL,
  LOCATION_STATUS,
  SETTINGS_VENDOR_STATUS,
  SETTINGS_PARAMETERS,
  SETTINGS_UNITS,
  SETTINGS_HANDLING_FEES,
  SETTINGS_CURRENCY,
  SETTINGS_SERVICE_ITEM_NAME,
  SETTINGS_SERVICE_CATEGORY,
  SETTINGS_PRICING_STATUS,
  SETTING_CONTACT_METHOD,
  SETTING_CONTACT_TYPE,
  SETTINGS_CONTACT_STATUS,
  SETTING_CONTACT_ACCESS_LEVEL,
  SETTING_COMMUNICATION_SERVICE,
  SETTING_USAGES_TYPE,
  SETTING_ADDRESS_TYPE,
  SETTING_DOCUMENT_NAME,
  SETTING_DOCUMENT_STATUS,
  SETTINGS_VENDOR_LEVEL,
  SETTINGS_CERTIFIED_MEMBER_FEE_SCHEDULE,
  SETTINGS_PAYMENTS_OPTIONS,
  SETTINGS_CREDIT_AVAILABLE,
  SETTINGS_MAIN_SERVICE_OFFERED,
  SETTINGS_OPERATON_TYPE,
  SETTING_DRIVER_LOCATION_CREW,
  SETTING_DRIVER_LOCATION_PAX,
  SETTING_AMENITIES,
  SETTING_AIRCRAFT_PARKING_OPTIONS,
  SETTING_AIRCRAFT_PARKING_DISTANCE_FBO,
  SETTING_AIRCRAFT_SPOT_ACCOMMODATION,
  SETTING_TOWBAR_SCENARIOS,
  SETTING_AVAILABLE_FACILITIES,
  SETTING_LUGGAGE_HANDLING,
  SETTING_ARRIVAL_CREW_PAX_PASSPORT_HANDLING,
  SETTING_DISABILITY_ACCOMMODATIONS,
  SETTING_HANGER_AVAILABLE_UOM,
  SETTING_BULLETIN_TYPES,
  SETTING_CAPPS_CATEGORY,
  SETTING_INTERNATIONAL_ARRIVAL_PROCEDURES,
  SETTING_INTERNATIONAL_DEPARTURE_PROCEDURES,
  SETTING_AIRPORT_HOURS_TYPE,
  SETTING_AIRPORT_HOURS_SUB_TYPE
}
