import { CoreModel, ISelectOption, modelProtection } from '@wings-shared/core';
import { VendorLocationModel } from './VendorLocation.model';
import { SettingBaseModel } from './SettingBase.model';
import { RootDataStore } from '@uplink-shared/layout';

@modelProtection
export class HandlerBulletinsModel extends CoreModel implements ISelectOption {
  id: number = 0;
  bulletinId: number = 0;
  bulletinLevelId: number = 0;
  bulletinEntity: VendorLocationModel = new VendorLocationModel();
  startDate: Date = new Date();
  endDate: Date = new Date();
  isUFN: boolean = false;
  notamNumber: string = '';
  bulletinText: string = '';
  appliedBulletinTypes: SettingBaseModel = new SettingBaseModel();
  bulletinLevel: SettingBaseModel = new SettingBaseModel();
  bulletinTypeIds: number[] = [];
  bulletinCAPPSCategoryId: number = 0;

  constructor(data?: Partial<HandlerBulletinsModel>) {
    super(data);
    Object.assign(this, data);
  }

  public serialize(id?: number,cappsCategoryCodeId: number): HandlerBulletinsModel {
    return {
      id: id ? id : this.id || 0,
      bulletinId: this.bulletinId || 0,
      bulletinLevelId: 2,
      bulletinTypeIds: [ this.appliedBulletinTypes?.id ],
      bulletinEntityId: this.bulletinEntity.id || RootDataStore.locationData.locationId,
      bulletinEntityCode: RootDataStore.locationData.locationCode,
      bulletinEntityName: this.bulletinEntity.name || RootDataStore.locationData.locationName,
      startDate: this.startDate,
      endDate:  !this.isUFN ? this.endDate: null,
      notamNumber: this.notamNumber,
      bulletinText: this.bulletinText,
      isUFN: this.isUFN || false,
      bulletinCAPPSCategoryId: cappsCategoryCodeId
    };
  }

  static deserialize(apiData: HandlerBulletinsModel): HandlerBulletinsModel {
    if (!apiData) {
      return new HandlerBulletinsModel();
    }
    const data: Partial<HandlerBulletinsModel> = {
      ...apiData,
      bulletinEntity: VendorLocationModel.deserialize({
        ...apiData.bulletinEntity,
        id: apiData.bulletinEntity.bulletinEntityId,
      }),
      bulletinLevel: SettingBaseModel.deserialize({
        ...apiData.bulletinLevel,
        id: apiData.bulletinLevel.bulletinLevelId,
      }),
      appliedBulletinTypes: SettingBaseModel.deserialize({
        ...apiData.appliedBulletinTypes[0]?.bulletinType,
        id: apiData.appliedBulletinTypes[0]?.bulletinType.bulletinTypeId
      }),
    };
    return new HandlerBulletinsModel(data);
  }

  static deserializeList(apiDataList: HandlerBulletinsModel[]): HandlerBulletinsModel[] {
    return apiDataList ? apiDataList.map(apiData => HandlerBulletinsModel.deserialize(apiData)) : [];
  }

  public get label(): string {
    return this.name;
  }

  public get value(): string | number {
    return this.id;
  }
}
