import React, { FC, useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { Box, withStyles } from '@material-ui/core';
import { useGridState } from '@uplink-shared/custom-ag-grid';
import CustomAccordion from '../../../../../Shared/Components/CustomAccordion/CustomAccordion';
import MainOperationalHours from '../MainTerminalOnly/MainOperationalHours/MainOperationalHours';
import { finalize, takeUntil, catchError, mergeMap } from 'rxjs/operators';
import { forkJoin, of } from 'rxjs';
import { AlertStore } from '@uvgo-shared/alert';
import { useUnsubscribe } from '@wings-shared/hooks';
import { IAPIGridRequest, IClasses, UIStore } from '@wings-shared/core';
import { SettingsStore, VendorLocationStore } from '../../../../../../Stores';
import { LocationHoursModel } from '../../../../../Shared/Models/LocationHours.model';
import { ModalStore } from '@uvgo-shared/modal-keeper';
import { RootDataStore } from '@uplink-shared/layout';
import { styles } from '../../AirportHours.styles';
import { SETTING_ID } from '../../../../../Shared';

interface Props {
  classes?: IClasses;
  vendorLocationStore: VendorLocationStore;
  settingsStore: SettingsStore;
}

const AirportHoursTab: FC<Props> = ({ classes, vendorLocationStore, settingsStore }) => {
  const gridState = useGridState();

  const loadSettingHoursId = () => {
    settingsStore?.getSettings(undefined, SETTING_ID.SETTING_AIRPORT_HOURS_TYPE).subscribe();
    settingsStore?.getSettings(undefined, SETTING_ID.SETTING_AIRPORT_HOURS_SUB_TYPE).subscribe();
  };

  const unsubscribe = useUnsubscribe();

  useEffect(() => {
    loadInitialData();
    loadSettingHoursId();
    vendorLocationStore.feeValue = 0;
  }, []);

  const loadInitialData = () => {
    vendorLocationStore.isTimeChanged = false;
    UIStore.setPageLoader(true);
    const request: IAPIGridRequest = {
      pageNumber: gridState.pagination.pageNumber,
      pageSize: gridState.pagination.pageSize,
      filterCollection: JSON.stringify([
        {
          propertyName: 'AirportReference.Id',
          propertyValue: RootDataStore.locationData.airportReferenceId,
        },
        {
          propertyName: 'AirportHoursType.Name',
          propertyValue: 'Operational',
          operator: 'and',
        },
        {
          propertyName: 'AirportHoursScheduleType.Name',
          propertyValue: 'Recurrence',
          operator: 'and',
        },
        {
          propertyName: 'AirportHoursSubType.Name',
          propertyValue: 'Operational Hours',
          operator: 'and',
        },
      ]),
    };
    vendorLocationStore.getAirportHours(request).subscribe(() => {
      UIStore.setPageLoader(false);
      ModalStore.close();
    });
  };

  const errorHandler = (errors: { [key: string]: string[] }): void => {
    Object.keys(errors).forEach(key => {
      const errorMessages = errors[key];
      errorMessages.forEach(message => {
        AlertStore.info(message);
      });
    });
  };

  const handleErrorResponse = error => {
    if (error.response?.data?.errors) {
      errorHandler(error.response?.data?.errors);
      return;
    }
    if (error?.message) {
      AlertStore.info(error?.message);
    }
  };

  const deleteAllRecords = () => {
    const ids = vendorLocationStore.timeDataHoursData && vendorLocationStore.timeDataHoursData.map(item => item.id);
    if (ids.length === 0) {
      ModalStore.close();
      return;
    }
    UIStore.setPageLoader(true);
    vendorLocationStore
      ?.deleteAirportHours(ids)
      .pipe(
        takeUntil(unsubscribe.destroy$),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe({
        next: (response: LocationHoursModel[]) => {
          AlertStore.info('Airport Hours saved successfully!');
          loadInitialData();
          ModalStore.close();
        },
        error: error => {
          handleErrorResponse(error);
        },
      });
  };

  const handleSave = () => {
    const hoursIdZeroData = vendorLocationStore.updatedHoursData.filter(item => item.id === 0);
    const hoursIdOtherData = vendorLocationStore.updatedHoursData.filter(item => item.id !== 0);

    if (hoursIdZeroData.length === 0 && hoursIdOtherData.length === 0) {
      deleteAllRecords();
      return;
    }

    const hoursTypeId = settingsStore.airportHoursType.filter(item => {
      return item.name === 'Operational';
    });

    const hoursSubTypeId = settingsStore.airportHoursSubType.filter(item => {
      return item.name === 'Operational Hours';
    });

    const apiCalls = [];

    UIStore.setPageLoader(true);
    apiCalls.push(
      vendorLocationStore
        ?.updateAirportHours(
          LocationHoursModel.airportHoursSerializeList(
            hoursIdOtherData,
            hoursTypeId[0].id || 1,
            hoursSubTypeId[0].id || 2
          )
        )
        .pipe(
          mergeMap(() => {
            return forkJoin(
              vendorLocationStore?.addAirportHours(
                LocationHoursModel.airportHoursSerializeList(
                  hoursIdZeroData,
                  hoursTypeId[0].id || 1,
                  hoursSubTypeId[0].id || 2
                )
              )
            );
          })
        )
        .pipe(
          catchError(error => {
            handleErrorResponse(error);
            return of(null);
          })
        )
        .pipe(
          takeUntil(unsubscribe.destroy$),
          finalize(() => UIStore.setPageLoader(false))
        )
        .subscribe(responses => {
          const allSuccessful = responses && responses.every(response => response !== null);

          if (allSuccessful) {
            AlertStore.info('Airport Hours saved successfully!');
            loadInitialData();
            ModalStore.close();
          }
        })
    );
  };

  return (
    <div className={classes.mainTerminalOnlyWrapper}>
      <Box>
        <CustomAccordion
          panelName="airportHours"
          panelHeading="Airport Hours (in local time)"
          panelContent={
            <MainOperationalHours handleSave={handleSave} locationHoursList={vendorLocationStore.airportHoursList} />
          }
        />
      </Box>
    </div>
  );
};

export default inject('vendorLocationStore', 'settingsStore')(withStyles(styles)(observer(AirportHoursTab)));
