import { createStyles, Theme } from '@material-ui/core';
import { Transform } from 'stream';

export const styles = createStyles((theme: Theme) => ({
  editorWrapperContainer: {
    overflow: 'auto',
    '& .MuiAccordionSummary-root.Mui-expanded':{
      minHeight:'inherit'
    },
    '& .MuiCollapse-root':{
      border:'1px solid rgb(197, 195, 195)'
    },
    '& .MuiAccordionSummary-root':{
      padding:0,
      //   backgroundColor:theme.palette.background.default
      backgroundColor:'#5f5f5f',
      color:'#fff'
    },
    '& .MuiAccordionSummary-content':{
      order:2
    },
    '& .MuiAccordionSummary-content.Mui-expanded':{
      margin:'12px 0'
    },
    '& .MuiAccordionSummary-expandIcon':{
      color:'#fff',
    },
    '& .MuiAccordionSummary-expandIcon.Mui-expanded':{
      transform:'rotate(90deg)'
    },
    '& .MuiAccordionDetails-root':{
      display:'block',
      padding:'20px'
    },
    '& .quarantineHoursWrapper':{
    },
    '& .radioGroupWrapper':{
      '& label':{
        width:'48%',
        margin:'20px 20px 0 0',
        border:'1px solid #D3D3D3',
        borderRadius:'4px',
        padding:'0px',
      },
      '& label:nth-child(-n+2)':{
        marginTop:'0'
      },
      '& label:nth-child(even)':{
        marginRight:'0',
      }
    }
  },
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(14),
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.text.secondary,
    fontWeight:'600',
    marginBottom:theme.spacing(1),
  },

}));
