import React, { FC, ReactNode, useEffect, useState } from 'react';
import { IAPISearchFiltersDictionary, IClasses } from '@wings-shared/core';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router';
import { TabContext, TabPanel } from '@material-ui/lab';
import { Box, Button, Checkbox, FormControlLabel, Tab, Tabs, Typography, styled, withStyles } from '@material-ui/core';
import { sidebarMenuOperationalInfo } from '../../../Shared/Components/SidebarMenu/SidebarMenuOperationalInfo';
import { ConfirmNavigate, DetailsEditorWrapper, SidebarStore } from '@uplink-shared/layout';
import { styles } from './AirportHours.styles';
import MainTerminalOnly from './Components/MainTerminalOnly/MainTerminalOnly';
import MainTerminalBoth from './Components/MainTerminalBoth/MainTerminalBoth';
import GatBuilding from './Components/GatBuilding/GatBuilding';

interface Props {
  classes?: IClasses;
  searchFilters: IAPISearchFiltersDictionary;
}

const TABS = [
  {
    label: 'Main Terminal (Only)',
    value: '1',
    component: MainTerminalOnly,
    desc: 'Main Terminal, used by both for airlines and private flights (no dedicated VIP area/terminal)',
  },
  {
    label: 'Main Terminal (Both)',
    value: '2',
    component: MainTerminalBoth,
    desc: 'Main Terminal, with a dedicated VIP area/terminal within it',
  },
  {
    label: 'GAT Building',
    value: '3',
    component: GatBuilding,
    desc: 'General Aviation Terminal (GAT) building (separate,stand alone facility)',
  },
];

const CustomTabs = styled(Tabs)({
  '& .MuiTabs-indicator': {
    backgroundColor: 'transparent',
  },
  width: '100%',
});

const CustomTab = styled(props => <Tab disableRipple {...props} />)(() => ({
  flex: 1,
  textTransform: 'none',
  padding: 0,
  color: '#7C7C7C',
  fontWeight: 600,
  fontSize: '14px',
  backgroundColor: '#D3D3D3',
  '&.Mui-selected': {
    color: '#202020',
    borderLeft: '4px solid #004BA0',
    background: '#FFFFFF'
  },
}));

const AirportHours: FC<Props> = ({ classes, searchFilters }) => {
  const navigate = useNavigate();
  const [ activeTab, setActiveTab ] = useState('1');
  const [ checkedTabs, setCheckedTabs ] = useState([ true, false, false ]);

  useEffect(() => {
    SidebarStore.setNavLinks(sidebarMenuOperationalInfo(), 'vendor', 'Operational Information', '/vendor/locations');
  }, []);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setActiveTab(newValue);
  };

  const handleCheckboxChange = (index: number, checked: boolean) => {
    const newCheckedTabs = [ ...checkedTabs ];
    newCheckedTabs[index] = checked;
    setCheckedTabs(newCheckedTabs);
  };

  const headerActions = (): ReactNode => {
    return (
      <>
        <Typography variant="h5">Available Handler Facilities at Airport</Typography>
        <Box sx={{ display: 'flex' }}>
          <div className={`${classes.defaultButton}`}>
            <Button
              color="primary"
              variant="outlined"
              onClick={() => navigate('/vendor/locations')}
              size="large"
              disabled={true}
            >
              Cancel
            </Button>
          </div>
          <div className={`${classes.primaryButton} ${classes.defaultButton}`}>
            <Button color="primary" variant="contained" size="large" disabled={true}>
              Save
            </Button>
          </div>
        </Box>
      </>
    );
  };

  return (
    <div className={classes?.mainAirportHoursWrapper}>
      <ConfirmNavigate>
        <DetailsEditorWrapper
          headerActions={headerActions()}
          isEditMode={true}
          classes={{ headerActions: classes.headerActions }}
        >
          <div className={classes?.editorWrapperContainer}>
            <TabContext value={activeTab}>
              <CustomTabs value={activeTab} onChange={handleChange} variant="fullWidth">
                {TABS.map((tab, index) => (
                  <CustomTab key={tab.value} label={tab.label} value={tab.value} />
                ))}
              </CustomTabs>
              {TABS.map((tab, index) => (
                <TabPanel key={tab.value} value={tab.value}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={checkedTabs[index]}
                        onChange={e => handleCheckboxChange(index, e.target.checked)}
                        color="primary"
                      />
                    }
                    className="checkbox-wrapper-header"
                    label={tab.desc}
                  />
                  {checkedTabs[index] && tab.component && (
                    <Box>
                      <tab.component />
                    </Box>
                  )}
                </TabPanel>
              ))}
            </TabContext>
          </div>
        </DetailsEditorWrapper>
      </ConfirmNavigate>
    </div>
  );
};

export default withStyles(styles)(observer(AirportHours));
