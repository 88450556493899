// CustomDialog.tsx
import React, { FC } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';
import { observer } from 'mobx-react';
import { useStyles } from './CustomDialog.style';

type Props = {
  title: string;
  message: string;
  yesButton: string;
  noButton: string;
  onNoClick: () => void;
  onYesClick: () => void;
  className: string;
};

const CustomDialog: FC<Props> = ({ title, message, yesButton, noButton, onNoClick, onYesClick, className }) => {
  const classes = useStyles();

  const [ open, setOpen ] = React.useState(true);

  const handleClose = () => {
    onYesClick();
    setOpen(false);
  };

  return (
    <Dialog open={open} onClose={handleClose} disableBackdropClick={true}>
      <div className={`${classes.dialog} ${className}`}>
        <WarningRoundedIcon className={classes.warningIcon} style={{ textAlign: 'center', display: 'inline-block' }} />

        <DialogTitle className={classes.title}>
          <strong>{title}</strong>
        </DialogTitle>
        <DialogContent className={classes.content}>{message}</DialogContent>
        <DialogActions>
          <Button onClick={onNoClick} className={classes.cancelButton}>
            {noButton}
          </Button>
          <Button onClick={handleClose} autoFocus variant="contained" className={`${classes.yesButton} yesButton`}>
            {yesButton}
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  );
};

export default observer(CustomDialog);
