import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core';
import { SidebarStore } from '@uplink-shared/layout';
import { sidebarMenuOperationalInfo } from '../../../Shared/Components/SidebarMenu/SidebarMenuOperationalInfo';
import AircraftLogistics from '../../../OperationalInformation/Handler/AircraftLogistics/AircraftLogistics';

const useStyles = makeStyles((theme) => ({
  vmsVendorsContainer:{
    backgroundColor:''
  }
}));

const OperationalInformation = () => {
  const classes = useStyles();

  useEffect(() => {
    SidebarStore.setNavLinks(
      sidebarMenuOperationalInfo(), 'vendor', 'Operational Information', '/vendor/locations', 'panel2'
    );
  }, []);

  return (
    <div className={classes.vmsVendorsContainer}>
      <AircraftLogistics />
    </div>
  );
};

export default OperationalInformation;
