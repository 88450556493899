export const apiUrls = {
  vendorSettings: 'api/VendorSettings',
  vendor:'api/Vendor',
  vendorLocation:'api/vendorLocation',
  vendorStatus:'api/VendorStatus',
  vendorContact:'api/VendorContact',
  vendorLocationContact:'api/VendorLocationContact',
  vendorLocationStatus:'api/VendorLocationStatus',
  vendorManagement:'api/vendormanagement',
  refData:'api/ReferenceData',
  vendorSettingsParameter:'api/Parameter',
  vendorSettingsUnits:'api/UOM',
  vendorSettingsHandlingFee:'api/HandlingFee',
  vendorSettingsCurrency: 'api/Currency',
  vendorSettingsServiceItemName:'api/ServiceItem',
  vendorSetingsServiceCategory:'api/ServiceCategory',
  vendorSettingsStatus:'api/ServiceItemPricingStatus',
  serviceItemPricing:'api/ServiceItemPricing',
  vendorMasterContact: 'api/Contact',
  vendorContactMethod:'api/ContactMethod',
  vendorContactType:'api/ContactType',
  vendorContactStatus:'api/ContactStatus',
  vendorContactAccessLevel:'api/AccessLevel',
  vendorContactCommunicationService:'api/ServiceComm',
  vendorContactUsageType:'api/ContactUsegeType',
  vendorLocationHours: '/api/VendorLocationHours',
  airportHoursReference: '/api/AirportHoursReference',
  vendorLocationServiceCommunication: 'api/VendorLocationServiceComm',
  vendorAddressType: 'api/AddressType',
  vendorAddress: 'api/VendorAddress',
  vendorLocationAddress: 'api/VendorLocationAddress',
  vendorDocumentName: 'api/DocumentName',
  vendorDocumentStatus: 'api/DocumentStatus',
  vendorDocument: 'api/VendorDocument',
  vendorLocationDocument: 'api/VendorLocationDocument',
  vendorLevel: 'api/VendorLevel',
  certifiedMemberFeeSchedule: 'api/CertifiedMemberFeeSchedule',
  paymentOptions: 'api/PaymentOptions',
  creditAvailable: 'api/CreditAvailable',
  mainServicesOffered: 'api/MainServicesOffered',
  operationType: 'api/OperationType',
  operationalEssential: 'api/OperationalEssential',
  pickUpLocation: 'api/PickUpLocation',
  amenities: 'api/Amenities',
  aircraftParkingOptions: 'api/AircraftParkingOptions',
  aircraftParkingDistanceFBO: 'api/AircraftParkingDistanceFBO',
  aircraftSpotAccommodation: 'api/AircraftSpotAccommodation',
  towbarScenarios: 'api/TowbarScenarios',
  availableFacilities: 'api/AvailableFacilities',
  luggageHandling: 'api/LuggageHandling',
  arrivalCrewPaxPassportHandling: 'api/ArrivalCrewPaxPassportHandling',
  disabilityAccommodations: 'api/DisabilityAccommodations',
  hangerAvailableUom: 'api/HangerAvailableUom',
  vendorLocationBank: 'api/VendorLocationBank',
  operationalInsight: 'api/OperationalInsight',
  approvalSettings:{
    add:'api/ApprovalSetting/Add',
    update:'api/ApprovalSetting/Update',
    getById:'api/ApprovalSetting/GetById',
    getByEntityName:'api/ApprovalSetting/GetByEntityName',
    getAll:'api/ApprovalSetting/GetAll'
  },
  approvalData:{
    addList:'api/ApprovalData/AddList',
    add:'api/ApprovalData/Add',
    update:'api/ApprovalData/Update',
    getById:'api/ApprovalData/GetById',
    getAllApprovalDatas:'api/ApprovalData/GetAllApprovalDatas',
    getAllWithEntityGroup:'api/ApprovalData/GetAllWithEntityGroup'
  },
  vendorUser: 'api/VendorUser',
  vendorUserEmail: 'api/VendorUser/SendMail',
  getUvGoAccessToken:'/api/VendorUser/GetUvGoAccessToken',
  oktaGroups: 'api/v1/groups',
  emailNotification:{
    sendPricingDataUpdateEmailNotification:'api/EmailNotification/SendPricingDataUpdateEmailNotification'
  },
  bulletin: 'api/Bulletin',
  bulletinTypes: 'api/BulletinType',
  cappsCategory: 'api/BulletinCappsCategory',
  createNewUser: '/api/v1/okta/users',
  internationalArrivalProcedures: 'api/InternationalArrivalProcedures',
  internationalDepartureProcedures: 'api/InternationalDepartureProcedures',
  vendorOnBoardSlideOneNTwo:'/api/VendorOnBoardSlideOneNTwo',
  vendorOnBoardSlideThree: '/api/OperationTypeDocument',
  vendorOnBoardSlideOneNTwoGetByVendorId:'/api/VendorOnBoardSlideOneNTwo/GetByVendorId',
  vendorOnboardTracking: '/api/VendorOnboardTracking'
};
