import React, { FC, useEffect, useState } from 'react';
import { useGridState } from '@uplink-shared/custom-ag-grid';
import { useUnsubscribe } from '@wings-shared/hooks';
import { CityModel, CountryModel, useBaseUpsertComponent } from '@uplink/shared';
import { fields } from './Fields';
import {
  IClasses,
  Utilities,
  IOptionValue,
  IAPIGridRequest,
  UIStore,
  IAPISearchFiltersDictionary,
} from '@wings-shared/core';
import { ConfirmNavigate, RootDataStore, SidebarStore } from '@uplink-shared/layout';
import { Switch, Typography, withStyles } from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import { styles } from './Slide1.styles';
import { EDITOR_TYPES, IGroupInputControls } from '@uplink-shared/form-controls';
import { SlideOneAndTwoStore, VendorLocationStore, VendorManagementStore } from '../../../../Stores';
import ViewInputControls from '../../../Shared/Components/ViewInputControls/ViewInputControls';
import { newVendorOnboarding, sidebarMenus } from '../../../Shared/Components/SidebarMenu/SidebarMenu';
import { VendorOnBoardSlideOneNTwoModel } from '../../../Shared';
import { finalize, takeUntil } from 'rxjs/operators';
import { AuthStore } from '@uplink-shared/security';
import { ModalStore } from '@uvgo-shared/modal-keeper';
import { Dialog } from '@uvgo-shared/dialog';
import { PrimaryButton } from '@uvgo-shared/buttons';

interface Props {
  classes?: IClasses;
  slideOneAndTwoStore: SlideOneAndTwoStore;
  vendorLocationStore: VendorLocationStore;
  vendorManagementStore: VendorManagementStore;
  searchFilters: IAPISearchFiltersDictionary;
  registerSaveData: (saveData: () => void) => void;
  activeStep: number;
  setActiveStep: React.Dispatch<number>;
  onNextButtonDisable: (boolean) => void;
}

const Slide1: FC<Props> = ({
  classes,
  searchFilters,
  slideOneAndTwoStore,
  vendorLocationStore,
  vendorManagementStore,
  registerSaveData,
  activeStep,
  setActiveStep,
  onNextButtonDisable,
}) => {
  const gridState = useGridState();
  const unsubscribe = useUnsubscribe();
  const useUpsert = useBaseUpsertComponent<VendorOnBoardSlideOneNTwoModel>({}, fields, searchFilters);
  const formRef = useUpsert.form;
  const [ countryList, setCountryList ] = useState([]);
  const [ statesList, setStatesList ] = useState([]);

  useEffect(() => {
    registerSaveData(saveData);
    return () => registerSaveData(null);
  }, []);

  const saveData = () => {
    upsertSlideOnAndTwo();
  };

  const validateForm = () => {
    return !formRef.isValid || formRef.hasError;
  };

  useEffect(() => {
    onNextButtonDisable(validateForm());
  }, [ formRef.changed, formRef.isValid, formRef.hasError ]);

  useEffect(() => {
    SidebarStore.setNavLinks(newVendorOnboarding(), 'vendor', 'Vendor Locations', '/vendor/locations');
    vendorLocationStore.getVmsIcaoCode().subscribe();
    vendorManagementStore.getVmsCountryCode().subscribe(response => {
      setCountryList(response.results);
    });
    loadInitialData();
  }, [ activeStep ]);

  const isCountrySelected = (): boolean => {
    const { value } = useUpsert.getField('hqAddressCountry');
    return Boolean((value as CountryModel)?.id);
  };

  const groupInputControls = (): IGroupInputControls[] => {
    return [
      {
        title: 'General Information:',
        inputControls: [
          {
            fieldKey: 'id',
            type: EDITOR_TYPES.TEXT_FIELD,
            isHidden: true,
          },
          {
            fieldKey: 'vendorId',
            type: EDITOR_TYPES.TEXT_FIELD,
            isHidden: true,
          },
          {
            fieldKey: 'airportReference',
            type: EDITOR_TYPES.DROPDOWN,
            options: vendorLocationStore.airportList,
            isFullFlex: true,
            isHidden: slideOneAndTwoStore.toggleSwitch,
          },
          {
            fieldKey: 'hqAddressCountry',
            type: EDITOR_TYPES.DROPDOWN,
            options: countryList,
            isHalfFlex: true,
            isHidden: !slideOneAndTwoStore.toggleSwitch,
          },
          {
            fieldKey: 'hqAddressCity',
            type: EDITOR_TYPES.DROPDOWN,
            options: vendorManagementStore.cities,
            isDisabled: !isCountrySelected(),
            isHalfFlex: true,
            isHidden: !slideOneAndTwoStore.toggleSwitch,
          },
          {
            fieldKey: 'locationName',
            type: EDITOR_TYPES.TEXT_FIELD,
            isFullFlex: true,
          },
          {
            fieldKey: 'companyLegalName',
            type: EDITOR_TYPES.TEXT_FIELD,
            isHalfFlex: true,
          },
          {
            fieldKey: 'companyWebsite',
            type: EDITOR_TYPES.TEXT_FIELD,
            isHalfFlex: true,
          },
          {
            fieldKey: 'accountReceivableContactName',
            type: EDITOR_TYPES.TEXT_FIELD,
            isHalfFlex: true,
          },
          {
            fieldKey: 'accountReceivableContactPhone',
            type: EDITOR_TYPES.TEXT_FIELD,
            isHalfFlex: true,
          },
        ],
      },
    ];
  };

  const errorHandler = (errors: object, id): void => {
    Object.values(errors)?.forEach(errorMessage => useUpsert.showAlert(errorMessage[0], id));
  };

  const upsertSlideOnAndTwo = () => {
    UIStore.setPageLoader(true);
    const request = new VendorOnBoardSlideOneNTwoModel({ ...useUpsert.form.values() });
    slideOneAndTwoStore
      .upsertSlideOneNTwo(request.serialize())
      .pipe(
        takeUntil(unsubscribe.destroy$),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe({
        next: (response: VendorOnBoardSlideOneNTwoModel) => {
          RootDataStore.setLocation(
            RootDataStore.locationData.locationId ? true : false,
            0,
            response.locationName,
            AuthStore.vendorProfile.code,
            response.airportReference?.id || RootDataStore.locationData.airportReferenceId,
            response.locationUniqueCode
          );
          setActiveStep(1);
          slideOneAndTwoStore.activeStep = 1;
        },
        error: error => {
          if (error.response.data.errors) {
            errorHandler(error.response.data.errors, request.id.toString());
            return;
          }
          useUpsert.showAlert(error.message, request.id.toString());
        },
      });
  };

  const filterStateByCountry = (value?: any) => {
    const filter = value
      ? JSON.stringify([
        {
          propertyName: 'Country.CountryId',
          propertyValue: value.id,
        },
      ])
      : '';

    const request: IAPIGridRequest = {
      filterCollection: filter,
    };
    vendorManagementStore.getVmsStates(request, undefined).subscribe(response => {
      setStatesList(response.results);
    });
  };

  const loadCities = (searchValue: string): void => {
    const countryId: number = useUpsert.getField('hqAddressCountry').value?.id;
    if (!countryId || !searchValue) {
      vendorManagementStore.cities = [];
      return;
    }
    const stateId: number = useUpsert.getField('hqAddressState').value?.id;
    const filters = stateId
      ? Utilities.getFilter('State.StateId', stateId)
      : Utilities.getFilter('Country.CountryId', countryId);

    const searchCityFilter = searchValue
      ? [
        {
          propertyName: 'CommonName',
          propertyValue: searchValue,
        },
        {
          propertyName: 'OfficialName',
          operator: 'or',
          propertyValue: searchValue,
        },
      ]
      : [];

    const filterCollection = [ filters ];
    const request: IAPIGridRequest = {
      filterCollection: JSON.stringify(filterCollection),
      searchCollection: JSON.stringify(searchCityFilter),
    };
    vendorManagementStore.getVmsCities(request).subscribe();
  };

  const onValueChange = (value: IOptionValue, fieldKey: string): void => {
    useUpsert.getField(fieldKey).set(value);
    slideOneAndTwoStore.formValueChanged = true
    switch (fieldKey) {
      case 'hqAddressCountry':
        vendorManagementStore.cities = [];
        vendorManagementStore.states = [];
        useUpsert.getField('hqAddressState').clear();
        useUpsert.getField('hqAddressCity').clear();
        filterStateByCountry(value);
        setCountryList(vendorManagementStore.countries);
        loadCities('');
        break;
      case 'hqAddressState':
        vendorManagementStore.cities = [];
        useUpsert.getField('hqAddressCity').clear();
        break;
      case 'hqAddressCity':
        vendorManagementStore.cities = [];
        break;
      default:
        break;
    }
    gridState.hasError = Utilities.hasInvalidRowData(gridState.gridApi);
  };

  const onSearch = (searchValue: string, fieldKey: string): void => {
    switch (fieldKey) {
      case 'hqAddressCountry':
        const filteredList = vendorManagementStore.countries.filter(country => {
          return (
            country.commonName?.toLowerCase().includes(searchValue.toLowerCase()) ||
            country.isO2Code?.toLowerCase().includes(searchValue.toLowerCase())
          );
        });
        setCountryList(filteredList);
        break;
      case 'hqAddressCity':
        loadCities(searchValue);
        break;
      case 'hqAddressState':
        const filteredStates = vendorManagementStore.states.filter(data => {
          return (
            data.commonName?.toLowerCase().includes(searchValue.toLowerCase()) ||
            data.cappsName?.toLowerCase().includes(searchValue.toLowerCase())
          );
        });
        setStatesList(filteredStates);
        break;
      case 'airportReference':
        vendorLocationStore.searchAirport(searchValue);
        break;
      default:
        break;
    }
    return;
  };

  const onFocus = (fieldKey: string): void => {
    switch (fieldKey) {
      case 'hqAddressState':
        const { value } = useUpsert.getField('hqAddressCountry');
        filterStateByCountry(value);
        break;
      case 'hqAddressCountry':
        setCountryList(vendorManagementStore.countries);
        break;
      case 'hqAddressCity':
        if (!useUpsert.getField('hqAddressCity').value) {
          vendorManagementStore.cities = [];
          useUpsert.getField('hqAddressCity').clear();
        }
        break;
      default:
        break;
    }
  };

  const loadInitialData = () => {
    slideOneAndTwoStore.formValueChanged = false;
    UIStore.setPageLoader(true);
    slideOneAndTwoStore
      .getByVendorId(AuthStore.vendorProfile?.id)
      .pipe(
        takeUntil(unsubscribe.destroy$),
        finalize(() => {
          UIStore.setPageLoader(false);
        })
      )
      .subscribe((response: VendorOnBoardSlideOneNTwoModel) => {
        if (response[0]) {
          useUpsert.setFormValues(VendorOnBoardSlideOneNTwoModel.deserialize(response[0]));
          if (response[0].airportReference) {
            slideOneAndTwoStore.toggleSwitch = false;
          } else {
            setFormValues(response[0]?.vendorLocationCityReference);
            slideOneAndTwoStore.toggleSwitch = true;
          }
          RootDataStore.setLocation(
            RootDataStore.locationData.locationId ? true : false,
            0,
            response[0].locationName,
            AuthStore.vendorProfile.code,
            response[0].airportReference?.id || RootDataStore.locationData.airportReferenceId,
            response[0].locationUniqueCode
          );
          if (response[0].operationType) {
            vendorLocationStore.operationTypeId = response[0].operationType?.id;
          } else {
            vendorLocationStore.operationTypeId = 0;
          }
        }
      });
  };

  const setFormValues = vendorAddress => {
    const countryModel = new CountryModel({
      id: vendorAddress?.countryReference.countryId,
      commonName: vendorAddress?.countryReference?.name,
      isO2Code: vendorAddress?.countryReference?.code,
    });
    const cityModel = new CityModel({
      id: vendorAddress?.cityReference?.cityId,
      cappsCode: vendorAddress?.cityReference?.code,
      commonName: vendorAddress?.cityReference?.name,
    });
    useUpsert.getField('hqAddressCountry').set(countryModel);
    useUpsert.getField('hqAddressCity').set(cityModel);
  };

  useEffect(() => {
    if (slideOneAndTwoStore.toggleSwitch) {
      useUpsert.getField('hqAddressCountry').set('rules', 'required');
      useUpsert.getField('hqAddressCountry').set('label', 'Country*');
      useUpsert.getField('hqAddressCity').set('rules', 'required');
      useUpsert.getField('hqAddressCity').set('label', 'City*');
      useUpsert.getField('airportReference').set('rules', '');
      useUpsert.getField('airportReference').set('label', 'ICAO');
      useUpsert.getField('airportReference').set(null);
    } else {
      useUpsert.getField('airportReference').set('rules', 'required');
      useUpsert.getField('airportReference').set('label', 'ICAO*');
      useUpsert.getField('hqAddressCountry').set('rules', '');
      useUpsert.getField('hqAddressCountry').set('label', 'Country');
      useUpsert.getField('hqAddressCity').set('rules', '');
      useUpsert.getField('hqAddressCity').set('label', 'City');
      useUpsert.getField('hqAddressCountry').set(null);
      useUpsert.getField('hqAddressCity').set(null);
    }
  }, [ slideOneAndTwoStore.toggleSwitch ]);

  const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const confirmSwitch = () => {
      slideOneAndTwoStore.toggleSwitch = !Boolean(event.target.checked);
      ModalStore.close();
    };

    ModalStore.open(
      <Dialog
        title={'Changing this will cause you to lose your previous information.'}
        open={true}
        onClose={() => ModalStore.close()}
        dialogContent={() => <Typography>Are you sure you want to continue?</Typography>}
        dialogActions={() => (
          <>
            <PrimaryButton variant={'outlined'} onClick={() => ModalStore.close()}>
              Cancel
            </PrimaryButton>
            <PrimaryButton variant={'contained'} onClick={confirmSwitch}>
              Confirm
            </PrimaryButton>
          </>
        )}
        disableBackdropClick={true}
        isPreventDrag={true}
        closeBtn={false}
      />
    );
  };

  return (
    <ConfirmNavigate isBlocker={slideOneAndTwoStore.formValueChanged}>
      <Typography className={classes.heading}>Add your location based on</Typography>
      <div className={classes.toggle}>
        <Typography>ICAO</Typography>
        <Switch
          checked={slideOneAndTwoStore.toggleSwitch}
          onChange={handleSwitchChange}
          color="primary"
          name="switch"
        />
        <Typography>Country & City</Typography>
      </div>
      <div className={classes.editorWrapperContainer}>
        <ViewInputControls
          isEditable={true}
          groupInputControls={groupInputControls()}
          onGetField={(fieldKey: string) => useUpsert.getField(fieldKey)}
          onValueChange={(option, fieldKey) => onValueChange(option, fieldKey)}
          field={fieldKey => useUpsert.getField(fieldKey)}
          onSearch={(searchValue: string, fieldKey: string) => onSearch(searchValue, fieldKey)}
          onFocus={fieldKey => onFocus(fieldKey)}
        />
      </div>
    </ConfirmNavigate>
  );
};

export default inject(
  'slideOneAndTwoStore',
  'vendorLocationStore',
  'vendorManagementStore'
)(withStyles(styles)(observer(Slide1)));
