import { EnvironmentVarsStore, ENVIRONMENT_VARS } from '@uplink-shared/env-store';

const env = new EnvironmentVarsStore();
export const baseApiPath = {
  vendorManagementCoreUrl: `${env.getVar(ENVIRONMENT_VARS.VENDOR_MANAGEMENT_CORE_API)}`,
  vendorManagementNoSqlUrl: `${env.getVar(ENVIRONMENT_VARS.VENDOR_MANAGEMENT_NOSQL_API)}`,
  noSqlData: `${env.getVar(ENVIRONMENT_VARS.REFERENCE_DATA_BASE_API)}/nosqlreferencedata`,
  userManagement: `${env.getVar(ENVIRONMENT_VARS.USER_MANAGEMENT_BASE_API)}`,
  airports: `${env.getVar(ENVIRONMENT_VARS.REFERENCE_DATA_BASE_API)}/airports`
};
