import { IAPIGridRequest, IAPISearchFiltersDictionary, IClasses, IOptionValue, UIStore } from '@wings-shared/core';
import React, { FC, ReactNode, useEffect, useState } from 'react';
import { NavigateFunction, useNavigate, useParams } from 'react-router';
import { inject, observer } from 'mobx-react';
import { Box, Tooltip, Typography, withStyles,FormControl, FormControlLabel, Radio,
  RadioGroup, TextField } from '@material-ui/core';
import { styles } from './UpsertUser.styles';
import { MixPanelTrackingEvents, useBaseUpsertComponent } from '@uplink/shared';
import { useUnsubscribe } from '@wings-shared/hooks';
import { fields } from './Fields';
import { EDITOR_TYPES, IGroupInputControls } from '@uplink-shared/form-controls';
import { finalize, takeUntil } from 'rxjs/operators';
import { DetailsEditorWrapper, SidebarStore, ConfirmNavigate } from '@uplink-shared/layout';
import Button from '@material-ui/core/Button';
import { SettingBaseModel, UserGroupModel } from '../../../Shared';
import { sidebarMenus } from '../../../Shared/Components/SidebarMenu/SidebarMenu';
import ViewInputControls from '../../../Shared/Components/ViewInputControls/ViewInputControls';
import { VendorUserStore, VendorLocationStore } from '../../../../Stores';
import { AuthStore } from '@uplink-shared/security';
import { AnalyticsStore } from '@uplink-shared/analytics';
import { VendorUserModel } from '../../../Shared/Models/VendorUser.model';
import { useGridState } from '@uplink-shared/custom-ag-grid';
import { UserManagementStore } from '../../../../Stores/UserManagement.store';
import { UserModel } from '../../../Shared/Models/User.model';
import { OperationInfoSettingOptionModel } from '../../../Shared/Models/OperationInfoSettingOptionModel.model';
import InfoIcon from '@material-ui/icons/Info';
import { Dialog } from '@uvgo-shared/dialog';
import { PrimaryButton } from '@uvgo-shared/buttons';
import { AlertStore } from '@uvgo-shared/alert';

interface Props {
  classes?: IClasses;
  vendorUserStore: VendorUserStore;
  vendorLocationStore: VendorLocationStore;
  userManagementStore: UserManagementStore;
  searchFilters?: IAPISearchFiltersDictionary;
  navigate?: NavigateFunction;
  params?: { vendorId: string; vendorName: string; vendorUserId: number };
}

const UpsertUser: FC<Props> = ({
  classes,
  vendorUserStore,
  vendorLocationStore,
  userManagementStore,
  searchFilters,
}) => {
  const unsubscribe = useUnsubscribe();
  const useUpsert = useBaseUpsertComponent<VendorUserModel>({}, fields, searchFilters);
  const formRef = useUpsert.form;
  const navigate = useNavigate();
  const params = useParams();
  const gridState = useGridState();
  const [ vendorUserRole, setVendorUserRole ] = useState([]);
  const [ userLocations, setUserLocations ] = useState([]);
  const [ existingOktaUser, setExistingOktaUser ] = useState(null);
  const [ radioValue, setRadioValue ] = useState(1)
  const [ isModalOpen, setIsModalOpen ] = useState(true);

  const getBasePath = (): string => {
    if (params.id) {
      return `vendor/user/upsert/${params.id}/`;
    }
    return 'vendor/user/upsert/';
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault()
    setRadioValue(Number(event.target.value));
  };

  useEffect(() => {
    AnalyticsStore.track(MixPanelTrackingEvents.VENDOR_USER);
    SidebarStore.setNavLinks(sidebarMenus(), getBasePath());
    loadInitialData();
    if (!userManagementStore.uvGoAccessToken) {
      userManagementStore.getUvGoAccessToken();
    }
    if (useUpsert.getField('email').value) {
      findExistingEmail(useUpsert.getField('userName').value);
    }
    loadLocationData();
  }, []);

  useEffect(() => {
    if (userManagementStore.uvGoAccessToken) {
      loadUplinkGroups();
    }
  }, [ userManagementStore.uvGoAccessToken ]);

  const title = (): string => {
    return params.id ? 'Update User' : 'Add User';
  };

  const checkExistingEmail = () => {
    const emailFieldVal = useUpsert.getField('email').value;
    if (emailFieldVal.length > 0) {
      findExistingEmail(useUpsert.getField('userName').value);
    }
  };

  const CustomTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: '#fff',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 300,
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9',
      padding: '8px',
      borderRadius: '4px',
      '& .MuiTooltip-arrow': {
        color: '#f5f5f9',
      },
    },
  }))(Tooltip);

  const groupInputControls = (): IGroupInputControls[] => {
    return [
      {
        title: '',
        inputControls: [
          {
            fieldKey: 'id',
            type: EDITOR_TYPES.TEXT_FIELD,
            isHidden: true,
          },
          {
            fieldKey: 'email',
            type: EDITOR_TYPES.TEXT_FIELD,
            onBlur: () => checkExistingEmail(),
          },
          {
            fieldKey: 'givenName',
            type: EDITOR_TYPES.TEXT_FIELD,
          },
          {
            fieldKey: 'surName',
            type: EDITOR_TYPES.TEXT_FIELD,
          },
          {
            fieldKey: 'userRole',
            type: EDITOR_TYPES.DROPDOWN,
            options: vendorUserRole,
            customLabel: field => {
              return (
                <div className={classes?.flexIcon}>
                  <span>User Role*</span>
                  
                  <CustomTooltip
                    placement='top-start'
                    title={
                      <>
                        <Typography color="inherit"><strong></strong>Information Ambassador:</Typography>
                        <span>
                          {`
                            has the ability to view and update Vendor Location records/data for all assigned locations, cannot edit Vendor Level.
                          `}
                        </span>
                      </>
                    }
                  >
                    <span className={classes?.imageIcon}>
                      <InfoIcon />
                    </span>
                  </CustomTooltip>
                </div>
              );
            },
          },
          {
            fieldKey: 'vendorUserLocation',
            type: EDITOR_TYPES.AUTO_COMPLETE,
            options: userLocations,
            isQuarterFlex: true,
            multiple: true,
            disableCloseOnSelect: true,
            // customErrorMessage: vendorUserStore.isLocationRequired ? 'This Location* field is required.' : ''
          },
        ],
      },
    ];
  };
  const onValueChange = (value: IOptionValue, fieldKey: string): void => {
    useUpsert.getField(fieldKey).set(value);
    const vendorLocationLength = useUpsert.getField('vendorUserLocation').value.length;

    switch (fieldKey) {
      case 'userRole':
        const isRoleInformationAmbassdor = useUpsert.getField('userRole').value?.name;
        break;
      case 'vendorUserLocation':
        break;
      default:
        break;
    }
  };

  const onSearch = (searchValue: string, fieldKey: string): void => {
    switch (fieldKey) {
      case 'userRole':
        const filteredList = userManagementStore.uplinkOktaGroups.filter(item => {
          const itemName = item?.name?.toLowerCase() || '';
          return itemName.includes(searchValue.toLowerCase());
        });
        setVendorUserRole(filteredList);
        break;

      case 'vendorUserLocation':
        const filteredLocations = vendorLocationStore.vendorLocationList.filter(data => {
          return (
              data.name?.toLowerCase().includes(searchValue.toLowerCase()) ||
              data.airportReference?.displayCode?.toLowerCase().includes(searchValue.toLowerCase()) ||
              data.vendorLocationCityReference?.cityReference?.name?.toLowerCase().includes(searchValue.toLowerCase())
          );
        });
        setUserLocations(filteredLocations);
        break;
      default:
        break;
    }
    return;
  };

  const onFocus = (fieldKey: string): void => {
    switch (fieldKey) {
      case 'name':
        break;
      default:
        break;
    }
  };

  const loadLocationData = (pageRequest?: IAPIGridRequest) => {
    const request: IAPIGridRequest = {
      pageNumber: gridState.pagination.pageNumber,
      pageSize: gridState.pagination.pageSize,
      filterCollection: JSON.stringify([
        {
          propertyName: 'Vendor.Id',
          propertyValue: AuthStore.vendorProfile.id,
        },
      ]),
    };
    vendorLocationStore?.getVMSComparison(request).subscribe((response)=>setUserLocations(response.results));
  };

  const loadInitialData = () => {
    if (params.id) {
      UIStore.setPageLoader(true);
      vendorUserStore
        ?.getVendorUserById(parseInt(params.id))
        .pipe(
          takeUntil(unsubscribe.destroy$),
          finalize(() => UIStore.setPageLoader(false))
        )
        .subscribe((response: VendorUserModel) => {
          setFormValues(response);
        });
    }
  };

  const loadUplinkGroups = () => {
    userManagementStore.loadUplinkOktaGroups().subscribe(response => {
      setVendorUserRole(response.results);
      const userRoleFieldValue = useUpsert.getField('userRole').value;
      if (userRoleFieldValue) {
        const selectedGoup = response.results.find(a => a.name == userRoleFieldValue.name);
        useUpsert.getField('userRole').set(selectedGoup);
      }
      else
      {
        useUpsert.getField('userRole').set(response.results[0]);
      }
      
    });
  };

  const findExistingEmail = (userName): void => {
    const request: IAPIGridRequest = {
      pageNumber: gridState.pagination.pageNumber,
      pageSize: gridState.pagination.pageSize,
      searchCollection: JSON.stringify([
        {
          propertyName: 'userName',
          propertyValue: userName ? userName : useUpsert.getField('email').value,
        },
      ]),
    };
    UIStore.setPageLoader(true);
    userManagementStore
      .checkIfUserExist(request, useUpsert.getField('email').value)
      .pipe(
        takeUntil(unsubscribe.destroy$),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe(
        (response: any) => {
          if (response.results.length > 0) {
            setExistingOktaUser(response.results[0]);
            useUpsert.showAlert('This username already exists','0');
            useUpsert.getField('givenName').set(response.results[0].FirstName);
            useUpsert.getField('surName').set(response.results[0].LastName);
            userManagementStore.isDataAvailable = true;
          } else {
            setIsModalOpen(false);
            setExistingOktaUser(null);
            useUpsert.getField('givenName').value = '';
            useUpsert.getField('surName').value = '';
          }
        },
        error => {
          if (error.response?.data.IsSuccess === false) {
            return useUpsert.showAlert(error.response?.data.Summary, '0');
          } else useUpsert.showAlert(error.message, '0');
        }
      );
  };

  const dialogHeader = (): ReactNode => {
    return params.id ? 'Edit User' : 'Add User';
  };

  const headerActions = (): ReactNode => {
    return (
      <>
        <Typography variant="h5">{dialogHeader()}</Typography>
        <Box sx={{ display: 'flex' }}>
          <div className={`${classes.defaultButton}`}>
            <Button color="primary" variant="outlined" onClick={() => navigate('/vendor/user')} size="large">
              Cancel
            </Button>
          </div>
          <div className={`${classes.primaryButton} ${classes.defaultButton}`}>
            <Button
              color="primary"
              variant="contained"
              onClick={() => saveUserData()}
              size="large"
              disabled={!formRef.changed || !formRef.isValid || formRef.hasError || vendorUserStore.isLocationRequired}
            >
              Save
            </Button>
          </div>
        </Box>
      </>
    );
  };

  const setFormValues = response => {
    response = VendorUserModel.deserialize(response);
    useUpsert.setFormValues(response);
    useUpsert.getField('givenName').set(response.FirstName ? response.FirstName : response.givenName);
    useUpsert.getField('surName').set(response.LastName ? response.LastName : response.surName);
    useUpsert.getField('userRole').set(response.name);
  };

  const upsertNewUser = (tempPassword) => {
    UIStore.setPageLoader(true);
    const selectedUserGroup: UserGroupModel = useUpsert.getField('userRole').value;
    const userId: string = useUpsert.getField('userId').value;
    const request = new VendorUserModel({ ...useUpsert.form.values() });
    request.vendorUserLocation = OperationInfoSettingOptionModel.deserializeList(
      useUpsert.getField('vendorUserLocation').value
    );
    vendorUserStore
      ?.upsertVendorUser(request.serialize(request.id, selectedUserGroup.name, userId, request.status.id))
      .pipe(
        takeUntil(unsubscribe.destroy$),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe({
        next: (response: VendorUserModel) => {
          if (!params.id) {
            AlertStore.info(`Email link has been sent successfully to ${useUpsert.form.values().email}.`);
            vendorUserStore.sendNewEmail(useUpsert.form.values().email, tempPassword, params.vendorId);
          }
          useUpsert.form.reset();
          setFormValues(response);
          useUpsert.resetFormValidations(response, () => {
            navigate('/vendor/user');
          });
        },
        error: error => {
          useUpsert.showAlert(error.message, request.id);
        },
      });
  };

  const saveUserData = () => {
    if (existingOktaUser || params.id) {
      generateTempPass();
    } else {
      upsertOktaUser();
    }
  };

  const upsertOktaUser = () => {
    const selectedUserGroup: UserGroupModel = useUpsert.getField('userRole').value;
    const userId: string = useUpsert.getField('userId').value;
    UIStore.setPageLoader(true);
    const request = new UserModel();

    userManagementStore
      .createNewUser(
        request.serialize(useUpsert.form.values(), selectedUserGroup.id, userId),
        useUpsert.getField('userName').value
      )
      .pipe(
        takeUntil(unsubscribe.destroy$),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe(
        response => {
          upsertNewUser(response?.TempPassword);
        },
        error => {
          if (error.response?.data.IsSuccess === false) {
            return useUpsert.showAlert(error.response?.data.Summary, '0');
          } else useUpsert.showAlert(error.message, '0');
        }
      );
  };

  const generateTempPass = () => {
    UIStore.setPageLoader(true);
    userManagementStore
      .generateTempPassword(useUpsert.form.values().email)
      .pipe(
        takeUntil(unsubscribe.destroy$),
        finalize(() => { })
      )
      .subscribe(
        response => {
          updateOktaGroup(response);
        },
        error => {
          UIStore.setPageLoader(false);
          if (error.response?.data.IsSuccess === false) {
            return useUpsert.showAlert(error.response?.data.Summary, '0');
          } else useUpsert.showAlert(error.message, '0');
        }
      );
  };

  const updateOktaGroup = tempPassword => {
    const selectedUserGroup: UserGroupModel = useUpsert.getField('userRole').value;
    const ids: number[] = [ selectedUserGroup.id ];
    const userId: string = useUpsert.getField('userId').value;

    UIStore.setPageLoader(true);
    const request = new UserModel();
    userManagementStore
      .loadUplinkOktaGroups(request.serialize(useUpsert.form.values(), ids, userId))
      .pipe(
        takeUntil(unsubscribe.destroy$),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe(
        () => {
          upsertNewUser(tempPassword);
        },
        error => {
          if (error.response?.data.IsSuccess === false) {
            return useUpsert.showAlert(error.response?.data.Summary, '0');
          } else useUpsert.showAlert(error.message, '0');
        }
      );
  };

  const errorHandler = (errors: object, id): void => {
    Object.values(errors)?.forEach(errorMessage => useUpsert.showAlert(errorMessage[0], id));
  };

  const dialogContent = () => {
    return (
      <>
        <PrimaryButton
          className={classes.dialogButton}
          variant="contained" color="primary"
          disabled={!useUpsert.getField('userName').value && radioValue == 2}

          onClick={() => {
            if (radioValue == 2) { findExistingEmail(useUpsert.getField('userName').value) } else {
              setIsModalOpen(false)
            }
          }}>
          Submit
        </PrimaryButton>
      </>
    );
  };

  return (
    <ConfirmNavigate isBlocker={formRef.changed}>
      <DetailsEditorWrapper
        headerActions={headerActions()}
        isEditMode={true}
        classes={{ headerActions: classes.headerActions }}
      >
        <div className={classes.editorWrapperContainer}>
          <ViewInputControls
            isEditable={true}
            groupInputControls={groupInputControls()}
            onGetField={(fieldKey: string) => useUpsert.getField(fieldKey)}
            onValueChange={(option, fieldKey) => onValueChange(option, fieldKey)}
            field={fieldKey => useUpsert.getField(fieldKey)}
            onSearch={(searchValue: string, fieldKey: string) => onSearch(searchValue, fieldKey)}
            onFocus={fieldKey => onFocus(fieldKey)}
          />
        </div>
      </DetailsEditorWrapper>
      {
        isModalOpen && !params.id &&
        <Dialog
          title={'Please choose an option.'}
          isPreventDrag
          open={isModalOpen}
          onClose={() => navigate('/vendor/user')}
          dialogContent={() => {
            return <FormControl>
              <div className={`${classes.RadioButton}`}>
                <RadioGroup
                  row
                  value={radioValue}
                  onChange={handleChange}
                >
                  <FormControlLabel value={1} control={<Radio />} label="Individual Email" />
                  <FormControlLabel value={2} control={<Radio />} label="Shared Email" />
                </RadioGroup>
              </div>
              {
                radioValue === 2 && <TextField
                  className={classes.UserNameField}
                  placeholder='User Name' label={'User Name'}
                  value={useUpsert.getField('userName').value}
                  onChange={(e) => useUpsert.getField('userName').set(e.target.value)} />
              }
            </FormControl>
          }}
          closeBtn={true}
          dialogActions={() => dialogContent()}
          
        />
      }
    </ConfirmNavigate>
  );
};

export default inject(
  'vendorUserStore',
  'vendorLocationStore',
  'userManagementStore'
)(withStyles(styles)(observer(UpsertUser)));
