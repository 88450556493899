import { IAPISearchFiltersDictionary, IClasses, IOptionValue, UIStore, Utilities } from '@wings-shared/core';
import React, { FC, ReactNode, useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { Box, Typography, withStyles } from '@material-ui/core';
import { MixPanelTrackingEvents, useBaseUpsertComponent, VIEW_MODE } from '@uplink/shared';
import { useGridState } from '@wings-shared/custom-ag-grid';
import { useUnsubscribe } from '@wings-shared/hooks';
import { EDITOR_TYPES, IGroupInputControls } from '@uplink-shared/form-controls';
import { DetailsEditorWrapper, ConfirmNavigate, SidebarStore, RootDataStore } from '@uplink-shared/layout';
import Button from '@material-ui/core/Button';
import { AnalyticsStore } from '@uplink-shared/analytics';
import { fields } from './Fields';
import { HandlerBulletinsModel, SETTING_ID } from '../../../../Shared';
import CustomTooltip from '../../../../Shared/Components/CustomTooltip/CustomTooltip';
import ViewInputControls from '../../../../Shared/Components/ViewInputControls/ViewInputControls';
import { styles } from './UpsertBulletins.styles';
import { SettingsStore, VendorLocationStore } from '../../../../../Stores';
import { finalize, takeUntil } from 'rxjs/operators';
import { useNavigate, useParams } from 'react-router';
import { sidebarMenuOperationalInfo } from '../../../../Shared/Components/SidebarMenu/SidebarMenuOperationalInfo';

interface Props {
  classes?: IClasses;
  vendorLocationStore: VendorLocationStore;
  searchFilters?: IAPISearchFiltersDictionary;
  settingsStore: SettingsStore;
}

const UpsertBulletins: FC<Props> = ({ classes, vendorLocationStore, settingsStore, searchFilters }) => {
  const gridState = useGridState();
  const unsubscribe = useUnsubscribe();
  const useUpsert = useBaseUpsertComponent<HandlerBulletinsModel>({}, fields, searchFilters);
  const formRef = useUpsert.form;
  const navigate = useNavigate();
  const params = useParams();
  const isEditable = useUpsert.isEditable;
  const [ noteTypes, setNoteTypes ] = useState([]);

  useEffect(() => {
    useUpsert.setViewMode((params.upsert.toUpperCase() as VIEW_MODE) || VIEW_MODE.EDIT);
    SidebarStore.setNavLinks(sidebarMenuOperationalInfo(), 'vendor', 'Operational Information', '/vendor/locations');
    settingsStore
      .getHandlerBulletinSettings(undefined, SETTING_ID.SETTING_BULLETIN_TYPES)
      .subscribe(response => setNoteTypes(response.results));
    settingsStore.getHandlerBulletinSettings(undefined, SETTING_ID.SETTING_CAPPS_CATEGORY).subscribe();
    if (params?.id) {
      loadInitialData();
    }
    vendorLocationStore.isEndDateRequired = false;
    vendorLocationStore.isNotesTextExist = true;
    AnalyticsStore.track(MixPanelTrackingEvents.VENDOR_GENERALINFORMATION);
    if (!useUpsert.getField('isUFN').value) {
      useUpsert.getField('endDate').set('label', 'End Date*');
    }
  }, []);

  const groupInputControls = (): IGroupInputControls[] => {
    return [
      {
        title: 'General Information:',
        inputControls: [
          {
            fieldKey: 'id',
            type: EDITOR_TYPES.TEXT_FIELD,
            isHidden: true,
          },
          {
            fieldKey: 'appliedBulletinTypes',
            type: EDITOR_TYPES.DROPDOWN,
            options: noteTypes,
            isFullFlex: true,
          },
          {
            fieldKey: 'startDate',
            type: EDITOR_TYPES.DATE,
            maxDate: useUpsert.getField('endDate').value,
          },
          {
            fieldKey: 'endDate',
            type: EDITOR_TYPES.DATE,
            minDate: useUpsert.getField('startDate').value,
            customErrorMessage: vendorLocationStore.isEndDateRequired ? 'The End Date* field is required.' : '',
            isDisabled: Boolean(useUpsert.getField('isUFN').value),
          },
          {
            fieldKey: 'isUFN',
            type: EDITOR_TYPES.CHECKBOX,
          },
          {
            fieldKey: 'notamNumber',
            type: EDITOR_TYPES.TEXT_FIELD,
            isFullFlex: true,
          },
          {
            fieldKey: 'bulletinText',
            type: EDITOR_TYPES.RICH_TEXT_EDITOR,
            isFullFlex: true,
            customErrorMessage: !vendorLocationStore.isNotesTextExist ? 'The Notes Text* field is required.' : '',
          },
        ],
      },
    ];
  };

  const htmltoText = (html: string) => {
    let text = html;
    text = text?.replace(/\n/gi, '');
    text = text?.replace(/<style([\s\S]*?)<\/style>/gi, '');
    text = text?.replace(/<script([\s\S]*?)<\/script>/gi, '');
    text = text?.replace(/<a.*?href='(.*?)[\\?\\'].*?>(.*?)<\/a.*?>/gi, ' $2 $1 ');
    text = text?.replace(/<\/div>/gi, '\n\n');
    text = text?.replace(/<\/li>/gi, '\n');
    text = text?.replace(/<li.*?>/gi, '  *  ');
    text = text?.replace(/<\/ul>/gi, '\n\n');
    text = text?.replace(/<\/p>/gi, '\n\n');
    text = text?.replace(/<br\s*[\\/]?>/gi, '\n');
    text = text?.replace(/<[^>]+>/gi, '');
    text = text?.replace(/^\s*/gim, '');
    text = text?.replace(/ ,/gi, ',');
    text = text?.replace(/ +/gi, ' ');
    text = text?.replace(/\n+/gi, '\n\n');
    return text;
  };

  const onValueChange = (value: IOptionValue, fieldKey: string): void => {
    useUpsert.getField(fieldKey).set(value);
    switch (fieldKey) {
      case 'startDate':
        if (useUpsert.getField('isUFN').value) {
          if (!useUpsert.getField('isUFN').value) vendorLocationStore.isEndDateRequired = false;
        } else if (!useUpsert.getField('isUFN').value) {
          vendorLocationStore.isEndDateRequired = true;
        }
        if (htmltoText(useUpsert.getField('bulletinText').value.toString())) {
          vendorLocationStore.isNotesTextExist = true;
        } else {
          vendorLocationStore.isNotesTextExist = false;
        }
        break;
      case 'isUFN':
        if (value) {
          useUpsert.getField('endDate').set('label', 'End Date');
          useUpsert.getField('endDate').set(null);
        } else {
          useUpsert.getField('endDate').set('label', 'End Date*');
        }
        if (!useUpsert.getField('endDate').value && !value) {
          vendorLocationStore.isEndDateRequired = true;
        } else {
          vendorLocationStore.isEndDateRequired = false;
        }
        break;
      case 'endDate':
        if (value) {
          if (!useUpsert.getField('isUFN').value) vendorLocationStore.isEndDateRequired = false;
        } else if (!useUpsert.getField('isUFN').value) {
          vendorLocationStore.isEndDateRequired = true;
        }
        break;
      case 'bulletinText':
        if (htmltoText(value.toString())) {
          vendorLocationStore.isNotesTextExist = true;
        } else {
          vendorLocationStore.isNotesTextExist = false;
        }
        break;
      default:
        break;
    }
    gridState.hasError = Utilities.hasInvalidRowData(gridState.gridApi);
  };

  const onSearch = (searchValue: string, fieldKey: string): void => {
    switch (fieldKey) {
      case 'appliedBulletinTypes':
        const filteredTypes = settingsStore.bulletinTypes?.filter(data => {
          return data.label?.toLowerCase().includes(searchValue.toLowerCase());
        });
        setNoteTypes(filteredTypes);
        break;
        break;
      default:
        break;
    }
    return;
  };

  const onFocus = (fieldKey: string): void => {
    switch (fieldKey) {
      case 'startDate':
        if (useUpsert.getField('isUFN').value) {
          if (!useUpsert.getField('isUFN').value) vendorLocationStore.isEndDateRequired = false;
        } else if (!useUpsert.getField('isUFN').value) {
          vendorLocationStore.isEndDateRequired = true;
        }
        if (htmltoText(useUpsert.getField('bulletinText').value.toString())) {
          vendorLocationStore.isNotesTextExist = true;
        } else {
          vendorLocationStore.isNotesTextExist = false;
        }
        break;
      case 'isUFN':
        if (useUpsert.getField(fieldKey).value) {
          useUpsert.getField('endDate').set('label', 'End Date');
        } else {
          useUpsert.getField('endDate').set('label', 'End Date*');
        }
        if (!useUpsert.getField('endDate').value && !useUpsert.getField(fieldKey).value) {
          vendorLocationStore.isEndDateRequired = true;
        } else {
          vendorLocationStore.isEndDateRequired = false;
        }
        break;
      case 'endDate':
        if (useUpsert.getField(fieldKey).value) {
          if (!useUpsert.getField('isUFN').value) vendorLocationStore.isEndDateRequired = false;
        } else if (!useUpsert.getField('isUFN').value) {
          vendorLocationStore.isEndDateRequired = true;
        }
        break;
      case 'bulletinText':
        if (htmltoText(useUpsert.getField(fieldKey).value.toString())) {
          vendorLocationStore.isNotesTextExist = true;
        } else {
          vendorLocationStore.isNotesTextExist = false;
        }
        break;
      default:
        break;
    }
  };

  const loadInitialData = () => {
    UIStore.setPageLoader(true);
    vendorLocationStore
      .getLocationBulletin(params?.id)
      .pipe(
        takeUntil(unsubscribe.destroy$),
        finalize(() => {
          UIStore.setPageLoader(false);
        })
      )
      .subscribe((response: HandlerBulletinsModel) => {
        useUpsert.setFormValues(HandlerBulletinsModel.deserialize(response));
        useUpsert.getField('appliedBulletinTypes').set(response.appliedBulletinTypes);
        if (response.isUFN) {
          vendorLocationStore.isEndDateRequired = false;
          useUpsert.getField('endDate').set('label', 'End Date');
        } else {
          if (!useUpsert.getField('endDate').value) {
            vendorLocationStore.isEndDateRequired = true;
          } else {
            vendorLocationStore.isEndDateRequired = false;
          }
          useUpsert.getField('endDate').set('label', 'End Date*');
        }
        if (response.bulletinText) {
          vendorLocationStore.isNotesTextExist = true;
        } else {
          vendorLocationStore.isNotesTextExist = false;
        }
      });
  };

  const headerTitle = () => {
    if (params.upsert.toUpperCase() === VIEW_MODE.NEW) return 'Add New Note';
    else if (params.upsert.toUpperCase() === VIEW_MODE.EDIT) return 'Edit Note';
    return 'Note Details';
  };

  const updateViewMode = () => {
    useUpsert.setViewMode(VIEW_MODE.EDIT);
    navigate(`/vendor/location/operational-information/handler-bulletins/edit/${params?.id}`);
  };

  const isButtonDisable = () => {
    if (params.upsert.toUpperCase() === VIEW_MODE.DETAILS) {
      return false;
    }
    return (
      !formRef.changed ||
      !formRef.isValid ||
      formRef.hasError ||
      vendorLocationStore.isEndDateRequired ||
      !vendorLocationStore.isNotesTextExist
    );
  };

  const headerActions = (): ReactNode => {
    return (
      <>
        <Typography variant="h5" style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
          {<CustomTooltip title={headerTitle()} />}
        </Typography>
        <Box sx={{ display: 'flex' }}>
          <div className={`${classes.defaultButton}`}>
            <Button
              color="primary"
              variant="outlined"
              onClick={() => navigate('/vendor/location/operational-information/handler-bulletins')}
              size="large"
            >
              {params.upsert.toUpperCase() === VIEW_MODE.DETAILS ? 'Back to List' : 'Cancel'}
            </Button>
          </div>
          <div className={`${classes.primaryButton} ${classes.defaultButton}`}>
            <Button
              color="primary"
              variant="contained"
              onClick={() =>
                params.upsert.toUpperCase() === VIEW_MODE.DETAILS ? updateViewMode() : upsertHandlerBulletin()
              }
              size="large"
              disabled={isButtonDisable()}
            >
              {params.upsert.toUpperCase() === VIEW_MODE.DETAILS ? 'Edit' : 'Save'}
            </Button>
          </div>
        </Box>
      </>
    );
  };

  const errorHandler = (errors: object, id): void => {
    Object.values(errors)?.forEach(errorMessage => useUpsert.showAlert(errorMessage[0], id));
  };

  const upsertHandlerBulletin = (): void => {
    UIStore.setPageLoader(true);
    const request = new HandlerBulletinsModel({ ...useUpsert.form.values() });
    const cappsCategory = settingsStore.cappsCategory.filter(
      item =>
        item.name?.toLocaleLowerCase() === request.appliedBulletinTypes?.name?.toLocaleLowerCase() ||
        item.name?.toLocaleLowerCase() === 'add\'l services'
    );

    vendorLocationStore
      ?.upsertVendorLocationHanlderBulletin(request.serialize(params?.id, cappsCategory[0]?.id))
      .pipe(
        takeUntil(unsubscribe.destroy$),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe({
        next: (response: HandlerBulletinsModel) => {
          useUpsert.form.reset();
          useUpsert.resetFormValidations(response, () => {
            navigate('/vendor/location/operational-information/handler-bulletins')
          });
        },
        error: error => {
          errorHandler(error.response?.data?.errors, request.id.toString());
        },
      });
  };

  return (
    <ConfirmNavigate isBlocker={formRef.changed}>
      <DetailsEditorWrapper
        headerActions={headerActions()}
        isEditMode={true}
        classes={{ headerActions: classes.headerActions }}
      >
        <div className={classes.editorWrapperContainer}>
          <ViewInputControls
            isEditable={isEditable}
            groupInputControls={groupInputControls()}
            onGetField={(fieldKey: string) => useUpsert.getField(fieldKey)}
            onValueChange={(option, fieldKey) => onValueChange(option, fieldKey)}
            field={fieldKey => useUpsert.getField(fieldKey)}
            onSearch={(searchValue: string, fieldKey: string) => onSearch(searchValue, fieldKey)}
            onFocus={fieldKey => onFocus(fieldKey)}
          />
        </div>
      </DetailsEditorWrapper>
    </ConfirmNavigate>
  );
};

export default inject('vendorLocationStore', 'settingsStore')(withStyles(styles)(observer(UpsertBulletins)));
