import { auditFields } from '@uplink/shared';

export const fields = {
  ...auditFields,
  id: {
    label: 'Bulletin Id',
  },
  appliedBulletinTypes: {
    label: 'Note Type*',
    rules: 'required'
  },
  bulletinEntity: {
    label: 'Note Entity',
  },
  bulletinLevel: {
    label : 'Note Level'
  },
  startDate: {
    label: 'Start Date*',
    rules: 'required'
  },
  endDate: {
    label: 'End Date',
  },
  isUFN: {
    label: 'UFN (Until further Notice)',
  },
  notamNumber: {
    label: 'NOTAM Number (if applicable)',
    rules: 'string|max:20',
  },
  bulletinText: {
    label: 'Notes Text*',
    rules: 'string|max:4000',
  },
}