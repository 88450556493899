import { createStyles, Theme } from '@material-ui/core';

export const styles = createStyles((theme: Theme) => ({
  editorWrapperContainer: {
    overflow: 'auto',
  },
  innerGrid: {
    margin: '10px',
    boxShadow: '0px 2px 3px 0px rgba(0, 0, 0, 0.1)',
    alignItems: 'center',
  },
  primaryButton: {
    '& button': {
      background: '#1976D2',
      color: '#fff',
      minWidth: '100px',
      height: '40px'
    },
  },
  heading:{
    fontSize: '14px',
    fontWeight: 'bold'
  }
}));
