import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme:Theme) => ({
  mainHeader: {
    justifyContent: 'space-between',
    borderBottom: '1px solid #D3D3D3',
    marginBottom: '20px',
    '& .MuiTypography-h6':{
      fontSize: '26px',
      textTransform: 'capitalize !important',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      wordWrap: 'normal',
      whiteSpace: 'nowrap',
      display:'inline',
    },
    '& .MuiTypography-h5':{
      paddingTop: '15px'
    },
    '& .MuiTypography-root':{
      paddingBottom: '10px'
    }
  }
}));