import React, { FC, useEffect, useState } from 'react';
import { IAPIGridRequest, IClasses, UIStore } from '@wings-shared/core';
import { inject, observer } from 'mobx-react';
import { Box, withStyles } from '@material-ui/core';
import CustomAccordion from '../../../../../Shared/Components/CustomAccordion/CustomAccordion';
import MainOperationalHours from './MainOperationalHours/MainOperationalHours';
import AccordionRadioGroups from '../../../../../Shared/Components/RadioGroup/AccordionRadioGroups';
import { VendorLocationStore } from '../../../../../../Stores';
import { useGridState } from '@uplink-shared/custom-ag-grid';
import { RootDataStore } from '@uplink-shared/layout';
import { LocationHoursModel } from '../../../../../Shared/Models/LocationHours.model';
import { finalize, takeUntil, catchError, mergeMap } from 'rxjs/operators';
import { forkJoin, of } from 'rxjs';
import { AlertStore } from '@uvgo-shared/alert';
import { ModalStore } from '@uvgo-shared/modal-keeper';
import { useUnsubscribe } from '@wings-shared/hooks';
import { styles } from '../../AirportHours.styles';

interface Props {
  classes?: IClasses;
  vendorLocationStore: VendorLocationStore;
}

const MainTerminalOnly: FC<Props> = ({ classes, vendorLocationStore }) => {
  const [ isQuarantineHoursYes, setIsQuarantineHoursYes ] = useState(false);
  const gridState = useGridState();
  const unsubscribe = useUnsubscribe();

  useEffect(() => {
    if (isQuarantineHoursYes) {
      loadQuarantineData();
    }
    loadMainTerminalData();
  }, [ isQuarantineHoursYes ]);

  const loadMainTerminalData = () => {
    vendorLocationStore.isTimeChanged = false;
    UIStore.setPageLoader(true);
    const request: IAPIGridRequest = {
      pageNumber: gridState.pagination.pageNumber,
      pageSize: gridState.pagination.pageSize,
      filterCollection: JSON.stringify([
        {
          propertyName: 'AirportReference.Id',
          propertyValue: RootDataStore.locationData.airportReferenceId,
        },
        {
          propertyName: 'AirportHoursType.Id',
          propertyValue: 1,
          operator: 'and',
        },
        {
          propertyName: 'AirportHoursScheduleType.Id',
          propertyValue: 1,
          operator: 'and',
        },
        {
          propertyName: 'AirportHoursSubType.Id',
          propertyValue: 20,
          operator: 'and',
        },
      ]),
    };
    vendorLocationStore.getAirportHours(request).subscribe(() => {
      UIStore.setPageLoader(false);
      ModalStore.close();
    });
  };

  const loadQuarantineData = () => {
    vendorLocationStore.isTimeChanged = false;
    UIStore.setPageLoader(true);
    const request: IAPIGridRequest = {
      pageNumber: gridState.pagination.pageNumber,
      pageSize: gridState.pagination.pageSize,
      filterCollection: JSON.stringify([
        {
          propertyName: 'AirportReference.Id',
          propertyValue: RootDataStore.locationData.airportReferenceId,
        },
        {
          propertyName: 'AirportHoursType.Id',
          propertyValue: 4,
          operator: 'and',
        },
        {
          propertyName: 'AirportHoursScheduleType.Id',
          propertyValue: 1,
          operator: 'and',
        },
        {
          propertyName: 'AirportHoursSubType.Id',
          propertyValue: 20,
          operator: 'and',
        },
      ]),
    };
    vendorLocationStore.getAirportHours(request, true).subscribe(() => {
      UIStore.setPageLoader(false);
      ModalStore.close();
    });
  };

  const quarantineHoursData = [
    { id: 1, value: 'no', label: 'No, CIQ is available all times the Main Terminal is open' },
    { id: 2, value: 'yes', label: 'Yes, they have limited hours' },
    { id: 3, value: 'n/a', label: 'N/A' },
  ];

  const ciqOvertimeData = [
    { id: 1, value: 'yes', label: 'Yes' },
    { id: 2, value: 'no', label: 'No' },
  ];

  const handleRadioChange = value => {
    if (value === 'yes') {
      setIsQuarantineHoursYes(true);
    } else {
      setIsQuarantineHoursYes(false);
    }
  };

  const errorHandler = (errors: { [key: string]: string[] }): void => {
    Object.keys(errors).forEach(key => {
      const errorMessages = errors[key];
      errorMessages.forEach(message => {
        AlertStore.info(message);
      });
    });
  };

  const handleErrorResponse = error => {
    if (error.response?.data?.errors) {
      errorHandler(error.response?.data?.errors);
      return;
    }
    if (error?.message) {
      AlertStore.info(error?.message);
    }
  };

  const handleSave = () => {
    const hoursIdZeroData = vendorLocationStore.updatedHoursData.filter(item => item.id === 0);
    const hoursIdOtherData = vendorLocationStore.updatedHoursData.filter(item => item.id !== 0);
    const ids = vendorLocationStore.airportHoursList && vendorLocationStore.airportHoursList.map(item => item.id);
    if (hoursIdZeroData.length === 0 && hoursIdOtherData.length === 0) {
      deleteAllRecords(ids);
      return;
    }

    const apiCalls = [];

    UIStore.setPageLoader(true);
    apiCalls.push(
      vendorLocationStore
        ?.updateAirportHours(LocationHoursModel.airportHoursSerializeList(hoursIdOtherData, 1, 20))
        .pipe(
          mergeMap(() => {
            return forkJoin(
              vendorLocationStore?.addAirportHours(LocationHoursModel.airportHoursSerializeList(hoursIdZeroData, 1, 20))
            );
          })
        )
        .pipe(
          catchError(error => {
            handleErrorResponse(error);
            return of(null);
          })
        )
        .pipe(
          takeUntil(unsubscribe.destroy$),
          finalize(() => UIStore.setPageLoader(false))
        )
        .subscribe(responses => {
          const allSuccessful = responses && responses.every(response => response !== null);

          if (allSuccessful) {
            AlertStore.info('Airport Hours saved successfully!');
            if (isQuarantineHoursYes) {
              loadQuarantineData();
            }
            loadMainTerminalData();
            ModalStore.close();
          }
        })
    );
  };

  const deleteAllRecords = (ids: number[]) => {
    if (ids.length === 0) {
      ModalStore.close();
      return;
    }
    UIStore.setPageLoader(true);
    vendorLocationStore
      ?.deleteAirportHours(ids)
      .pipe(
        takeUntil(unsubscribe.destroy$),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe({
        next: (response: LocationHoursModel[]) => {
          AlertStore.info('Airport Hours saved successfully!');
          if (isQuarantineHoursYes) {
            loadQuarantineData();
          }
          loadMainTerminalData();
          ModalStore.close();
        },
        error: error => {
          handleErrorResponse(error);
        },
      });
  };

  const handleSaveCIQ = () => {
    const hoursIdZeroData = vendorLocationStore.updatedHoursData?.filter(item => item.id === 0);
    const hoursIdOtherData = vendorLocationStore.updatedHoursData?.filter(item => item.id !== 0);
    const ids =
      vendorLocationStore.quarantineAirportHoursList &&
      vendorLocationStore.quarantineAirportHoursList.map(item => item.id);

    if (hoursIdZeroData.length === 0 && hoursIdOtherData.length === 0) {
      deleteAllRecords(ids);
      return;
    }

    const apiCalls = [];

    UIStore.setPageLoader(true);
    apiCalls.push(
      vendorLocationStore
        ?.updateAirportHours(LocationHoursModel.airportHoursSerializeList(hoursIdOtherData, 4, 20))
        .pipe(
          mergeMap(() => {
            return forkJoin(
              vendorLocationStore?.addAirportHours(LocationHoursModel.airportHoursSerializeList(hoursIdZeroData, 4, 20))
            );
          })
        )
        .pipe(
          catchError(error => {
            handleErrorResponse(error);
            return of(null);
          })
        )
        .pipe(
          takeUntil(unsubscribe.destroy$),
          finalize(() => UIStore.setPageLoader(false))
        )
        .subscribe(responses => {
          const allSuccessful = responses && responses.every(response => response !== null);

          if (allSuccessful) {
            AlertStore.info('Airport Hours saved successfully!');
            if (isQuarantineHoursYes) {
              loadQuarantineData();
            }
            loadMainTerminalData();
            ModalStore.close();
          }
        })
    );
  };

  return (
    <div className={classes.mainTerminalOnlyWrapper}>
      <Box>
        <CustomAccordion
          panelName="mainOperationalHours"
          panelHeading="Main Terminal - Operational Hours (in local time)"
          panelContent={
            <MainOperationalHours handleSave={handleSave} locationHoursList={vendorLocationStore.airportHoursList} />
          }
        />
      </Box>
      <Box>
        <CustomAccordion
          panelName="quarantineHours"
          panelHeading="Customs/Immigration/Quarantine hours"
          panelBodyHeading="Are Customs/Immigration/Quarantine (CIQ) hours limited in the Main Terminal?"
          // panelContent={<QuarantineHours divShowHide={true} onRadioChange={handleRadioChange} />}
          panelContent={
            <AccordionRadioGroups
              radioGroupData={quarantineHoursData}
              divShowHide={true}
              onRadioChange={handleRadioChange}
            />
          }
        />
      </Box>
      {isQuarantineHoursYes && (
        <>
          <Box>
            <CustomAccordion
              panelName="ciqOperationalHours"
              panelHeading="CIQ - Operational Hours (in local time)"
              panelContent={
                <MainOperationalHours
                  handleSave={handleSaveCIQ}
                  locationHoursList={vendorLocationStore.quarantineAirportHoursList}
                />
              }
            />
          </Box>
          <Box>
            <CustomAccordion
              panelName="ciqOvertime"
              panelHeading="CIQ overtime"
              panelBodyHeading="Is CIQ overtime possible?"
              panelContent={<AccordionRadioGroups radioGroupData={ciqOvertimeData} />}
            />
          </Box>
        </>
      )}
    </div>
  );
};

export default inject('vendorLocationStore')(withStyles(styles)(observer(MainTerminalOnly)));
