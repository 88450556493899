import React, { FC, useEffect, useState } from 'react';
import { IAPIGridRequest, IClasses, UIStore } from '@wings-shared/core';
import { inject, observer } from 'mobx-react';
import { Box, FormControl, OutlinedInput, withStyles, FormHelperText } from '@material-ui/core';
import CustomAccordion from '../../../../../Shared/Components/CustomAccordion/CustomAccordion';
import AccordionRadioGroups from '../../../../../Shared/Components/RadioGroup/AccordionRadioGroups';
import { ServiceItemPricingStore, SettingsStore, VendorLocationStore } from '../../../../../../Stores';
import { useGridState } from '@uplink-shared/custom-ag-grid';
import MainTerminalBothOperationalHours from './Component/MainTerminalBothOperationalHours';
import { LocationHoursModel } from '../../../../../Shared/Models/LocationHours.model';
import { finalize, takeUntil, catchError, mergeMap } from 'rxjs/operators';
import { forkJoin, of } from 'rxjs';
import { AlertStore } from '@uvgo-shared/alert';
import { useUnsubscribe } from '@wings-shared/hooks';
import { ModalStore } from '@uvgo-shared/modal-keeper';
import { RootDataStore } from '@uplink-shared/layout';
import { styles } from '../../AirportHours.styles';
import { ServiceItemPricingModel, SETTING_ID } from '../../../../../Shared';
import moment from 'moment';
import { NO_SQL_COLLECTIONS } from '@uplink/shared';

const dayNames = [ 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday' ];

interface TimeRange {
  id: string;
  hoursId: number;
  scheduleId: number;
  patternedRecurrenceId: number;
  startTime: string;
  endTime: string;
  is24Hours: boolean;
  isNew?: boolean;
  sequence?: number;
  hoursTypeId?: number;
  statusId?: number;
  accessLevelId?: number;
  startDate?: Date;
  endDate?: Date;
  includeHoliday?: boolean;
  dayOfWeekId?: number;
  patternedRecurrenceDaysofWeekId?: number;
  active?: boolean;
}

interface Props {
  classes?: IClasses;
  vendorLocationStore: VendorLocationStore;
  settingsStore: SettingsStore;
  serviceItemPricingStore: ServiceItemPricingStore;
  onNextButtonDisable?: (boolean) => void;
  registerSaveData: (saveData: () => void) => void;
}

const MainTerminalBoth: FC<Props> = ({
  classes,
  vendorLocationStore,
  onNextButtonDisable,
  registerSaveData,
  serviceItemPricingStore,
  settingsStore,
}) => {
  const gridState = useGridState();
  const [ overTimeValue, setOverTimeValue ] = useState(vendorLocationStore.overTimeValue);
  const [ feeValue, setFeeValue ] = useState(0);
  const [ error, setError ] = useState(false);

  const dayOfWeekIds: { [key: string]: number } = {
    Sunday: 1,
    Monday: 2,
    Tuesday: 3,
    Wednesday: 4,
    Thursday: 5,
    Friday: 6,
    Saturday: 7,
  };

  useEffect(() => {
    registerSaveData(saveData);
    return () => registerSaveData(null);
  }, []);

  const handleInputValue = event => {
    const value = event.target.value;
    const validFloat = /^-?\d*\.?\d*$/;
    vendorLocationStore.priceValue = value;
    if (validFloat.test(value)) {
      setError(false);
    } else {
      setError(true);
    }
  };

  const loadPricingData = () => {
    UIStore.setPageLoader(true);
    const request: IAPIGridRequest = {
      pageNumber: gridState.pagination.pageNumber,
      pageSize: gridState.pagination.pageSize,
      filterCollection: JSON.stringify([
        {
          propertyName: 'ServiceItem.Id',
          propertyValue: '744',
        },
        {
          propertyName: 'VendorLocation.VendorLocationId',
          propertyValue: [ RootDataStore.locationData.locationId ],
          filterType: 'in',
          operator: 'and',
        },
      ]),
    };
    serviceItemPricingStore.getVMSComparison(request).subscribe(response => {
      UIStore.setPageLoader(false);
      const result = ServiceItemPricingModel.deserialize(response.results[0]);
      serviceItemPricingStore.pricingId = result.id;
      vendorLocationStore.priceValue = result.price;
      vendorLocationStore.feeValue = result.uom.id;
    });
  };

  const pricingErrorHandler = (errors: object): void => {
    Object.values(errors)?.forEach(errorMessage => AlertStore.info(errorMessage[0]));
  };

  const saveData = () => {
    UIStore.setPageLoader(true);

    if (vendorLocationStore.priceValue && vendorLocationStore.feeValue) {
      updateServiceItemPricing()
        .pipe(
          takeUntil(unsubscribe.destroy$),
          finalize(() => UIStore.setPageLoader(false))
        )
        .subscribe({
          next: () => loadPricingData(),
          error: handlePricingError,
        });
    }

    updateAirportHoursIfNeeded();
  };

  const updateServiceItemPricing = () => {
    const model = new ServiceItemPricingModel();
    const serializedPricing = model.serializeHoursPricing(
      serviceItemPricingStore.pricingId,
      744,
      vendorLocationStore.priceValue,
      vendorLocationStore.feeValue
    );

    return serviceItemPricingStore?.upsertServiceItemPricingLocations([ serializedPricing ]);
  };

  const handlePricingError = error => {
    const errors = error?.response?.data?.errors;
    if (errors) {
      pricingErrorHandler(errors);
      return;
    }
    AlertStore.info(error.message);
  };

  const updateAirportHoursIfNeeded = () => {
    if (vendorLocationStore.airportHoursList.length === 0) return;

    UIStore.setPageLoader(true);

    const hoursTypeId = getHoursTypeId('Operational') || 1;
    const hoursSubTypeId = getHoursSubTypeId('VIP Terminal') || 22;

    vendorLocationStore
      ?.updateAirportHours(
        LocationHoursModel.airportHoursSerializeList(
          vendorLocationStore.overTimeHoursData,
          hoursTypeId,
          hoursSubTypeId,
          vendorLocationStore.overTimeValue === 1,
          settingsStore.airportHoursConditionType[0].conditionTypeId,
          settingsStore.airportHoursConditionValue[0].overtimeId,
          settingsStore.airportHoursConditionOperator[0].conditionalOperatorId
        )
      )
      .pipe(
        takeUntil(unsubscribe.destroy$),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe(() => {
        loadInitialData();
      });
  };

  const getHoursTypeId = name => {
    const type = settingsStore.airportHoursType.find(item => item.name === name);
    return type?.id;
  };

  const getHoursSubTypeId = name => {
    const subType = settingsStore.airportHoursSubType.find(item => item.name === name);
    return subType?.id;
  };

  const unsubscribe = useUnsubscribe();

  useEffect(() => {
    loadInitialData();
    loadPricingData();
    loadSettingHoursId();
    vendorLocationStore.priceValue = '';
    vendorLocationStore.feeValue = 0;
  }, []);

  const loadSettingHoursId = () => {
    settingsStore?.getSettings(undefined, SETTING_ID.SETTING_AIRPORT_HOURS_TYPE).subscribe();
    settingsStore?.getSettings(undefined, SETTING_ID.SETTING_AIRPORT_HOURS_SUB_TYPE).subscribe();
    const typeRequest: IAPIGridRequest = {
      pageNumber: gridState.pagination.pageNumber,
      pageSize: gridState.pagination.pageSize,
      filterCollection: JSON.stringify([
        {
          propertyName: 'Name',
          propertyValue: 'Overtime',
        },
      ]),
    };
    const valueRequest: IAPIGridRequest = {
      pageNumber: gridState.pagination.pageNumber,
      pageSize: gridState.pagination.pageSize,
      filterCollection: JSON.stringify([
        {
          propertyName: 'Name',
          propertyValue: 'On Request',
        },
      ]),
    };
    const operatorRequest: IAPIGridRequest = {
      pageNumber: gridState.pagination.pageNumber,
      pageSize: gridState.pagination.pageSize,
      filterCollection: JSON.stringify([
        {
          propertyName: 'Operator',
          propertyValue: '=',
        },
      ]),
    };
    settingsStore
      .getAirportHoursConditionTypeSettings(NO_SQL_COLLECTIONS.AIRPORT_CONDITION_TYPE, typeRequest)
      .subscribe();
    settingsStore
      .getAirportHoursConditionTypeSettings(NO_SQL_COLLECTIONS.AIRPORT_CONDITION_VALUE, valueRequest)
      .subscribe();
    settingsStore
      .getAirportHoursConditionTypeSettings(NO_SQL_COLLECTIONS.AIRPORT_CONDITIONAL_OPERATOR, operatorRequest)
      .subscribe();
  };

  const loadInitialData = () => {
    vendorLocationStore.isTimeChanged = false;
    UIStore.setPageLoader(true);
    const request: IAPIGridRequest = {
      pageNumber: gridState.pagination.pageNumber,
      pageSize: gridState.pagination.pageSize,
      filterCollection: JSON.stringify([
        {
          propertyName: 'AirportReference.Id',
          propertyValue: RootDataStore.locationData.airportReferenceId,
        },
        {
          propertyName: 'AirportHoursType.Name',
          propertyValue: 'Operational',
          operator: 'and',
        },
        {
          propertyName: 'AirportHoursScheduleType.Name',
          propertyValue: 'Recurrence',
          operator: 'and',
        },
        {
          propertyName: 'AirportHoursSubType.Name',
          propertyValue: 'VIP Terminal',
          operator: 'and',
        },
      ]),
    };
    vendorLocationStore.getAirportHours(request).subscribe(response => {
      UIStore.setPageLoader(false);
      formatHoursData();
      if (vendorLocationStore.airportHoursList.length === 0) {
        setOverTimeValue(2);
        vendorLocationStore.overTimeValue = 2;
        return;
      }
      if (response.results && response.results[0]?.airportHoursCondition !== null) {
        setOverTimeValue(1);
        vendorLocationStore.overTimeValue = 1;
      } else {
        setOverTimeValue(2);
        vendorLocationStore.overTimeValue = 2;
      }
    });
  };

  const validateForm = () => {
    return error || !vendorLocationStore.airportHoursList;
  };

  useEffect(() => {
    onNextButtonDisable(validateForm());
  }, [ vendorLocationStore.feeValue, vendorLocationStore.priceValue ]);

  const areaTerminalApplicationData = [
    { id: 55, value: 'Per Person', label: 'Per person, per use (arrival or departure)' },
    { id: 30, value: 'Per Use', label: 'Per facility use (regardless of pax count)' },
  ];

  const isOvertimePossibleData = [
    { id: 1, value: 'yes', label: 'Yes' },
    { id: 2, value: 'no', label: 'No' },
  ];

  const errorHandler = (errors: { [key: string]: string[] }): void => {
    Object.keys(errors).forEach(key => {
      const errorMessages = errors[key];
      errorMessages.forEach(message => {
        AlertStore.info(message);
      });
    });
  };

  const handleErrorResponse = error => {
    if (error.response?.data?.errors) {
      errorHandler(error.response?.data?.errors);
      return;
    }
    if (error?.message) {
      AlertStore.info(error?.message);
    }
  };

  const deleteAllRecords = () => {
    const ids = vendorLocationStore.timeDataHoursData && vendorLocationStore.timeDataHoursData.map(item => item.id);
    if (ids.length === 0) {
      ModalStore.close();
      return;
    }
    UIStore.setPageLoader(true);
    vendorLocationStore
      ?.deleteAirportHours(ids)
      .pipe(
        takeUntil(unsubscribe.destroy$),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe({
        next: (response: LocationHoursModel[]) => {
          AlertStore.info('Airport Hours saved successfully!');
          loadInitialData();
          ModalStore.close();
        },
        error: error => {
          handleErrorResponse(error);
        },
      });
  };

  const handleSave = () => {
    const hoursIdZeroData = vendorLocationStore.updatedHoursData.filter(item => item.id === 0);
    const hoursIdOtherData = vendorLocationStore.updatedHoursData.filter(item => item.id !== 0);

    if (hoursIdZeroData.length === 0 && hoursIdOtherData.length === 0) {
      deleteAllRecords();
      return;
    }

    const hoursTypeId = settingsStore.airportHoursType.filter(item => {
      return item.name === 'Operational';
    });

    const hoursSubTypeId = settingsStore.airportHoursSubType.filter(item => {
      return item.name === 'VIP Terminal';
    });

    const apiCalls = [];

    UIStore.setPageLoader(true);
    apiCalls.push(
      vendorLocationStore
        ?.updateAirportHours(
          LocationHoursModel.airportHoursSerializeList(
            hoursIdOtherData,
            hoursTypeId[0].id || 1,
            hoursSubTypeId[0].id || 22,
            vendorLocationStore.overTimeValue === 1,
            settingsStore.airportHoursConditionType[0].conditionTypeId,
            settingsStore.airportHoursConditionValue[0].overtimeId,
            settingsStore.airportHoursConditionOperator[0].conditionalOperatorId
          )
        )
        .pipe(
          mergeMap(() => {
            return forkJoin(
              vendorLocationStore?.addAirportHours(
                LocationHoursModel.airportHoursSerializeList(
                  hoursIdZeroData,
                  hoursTypeId[0].id || 1,
                  hoursSubTypeId[0].id || 22,
                  vendorLocationStore.overTimeValue === 1,
                  settingsStore.airportHoursConditionType[0].conditionTypeId,
                  settingsStore.airportHoursConditionValue[0].overtimeId,
                  settingsStore.airportHoursConditionOperator[0].conditionalOperatorId
                )
              )
            );
          })
        )
        .pipe(
          catchError(error => {
            handleErrorResponse(error);
            return of(null);
          })
        )
        .pipe(
          takeUntil(unsubscribe.destroy$),
          finalize(() => UIStore.setPageLoader(false))
        )
        .subscribe(responses => {
          const allSuccessful = responses && responses.every(response => response !== null);

          if (allSuccessful) {
            AlertStore.info('Airport Hours saved successfully!');
            loadInitialData();
            ModalStore.close();
          }
        })
    );
  };

  const onFeeApplicationValueChange = value => {
    setFeeApplicationValue(value);
  };

  function convertToISOFormat(time: string) {
    if (moment(time, 'HH:mm', true).isValid()) {
      const [ hours, minutes ] = time.split(':');
      const date = new Date();
      date.setUTCHours(hours, minutes, 0, 0);
      return date.toISOString();
    }
  }

  const groupDaysByTimeRange = (editableTimeData: { [key: string]: TimeRange[] }) => {
    const groupedTimeData: { [key: string]: any } = {};

    for (const day in editableTimeData) {
      if (editableTimeData.hasOwnProperty(day)) {
        editableTimeData[day].forEach(
          ({
            hoursId,
            sequence,
            hoursTypeId,
            statusId,
            accessLevelId,
            startTime,
            endTime,
            patternedRecurrenceDaysofWeekId,
            startDate,
            endDate,
            is24Hours,
            includeHoliday,
            patternedRecurrenceId,
            scheduleId,
          }) => {
            const timeKey = `${startTime}-${endTime}`;

            if (groupedTimeData[timeKey]) {
              groupedTimeData[
                timeKey
              ].scheduleRequest.patternedRecurrenceRequest.patternedRecurrenceDaysofWeekRequest.push({
                id: patternedRecurrenceDaysofWeekId,
                dayOfWeekId: dayOfWeekIds[day],
              });
            } else {
              groupedTimeData[timeKey] = {
                id: hoursId,
                userId: '',
                vendorLocationId: RootDataStore.locationData.locationId,
                sequence,
                hoursTypeId,
                statusId,
                accessLevelId,
                hoursScheduleTypeId: 1,
                scheduleRequest: {
                  id: scheduleId,
                  startTime: convertToISOFormat(startTime),
                  endTime: convertToISOFormat(endTime),
                  startDate,
                  endDate,
                  is24Hours: Boolean(
                    moment(startTime)
                      .utc()
                      .format('HH:mm') === '00:01' &&
                      moment(endTime)
                        .utc()
                        .format('HH:mm') === '23:59'
                  ),
                  includeHoliday,
                  patternedRecurrenceRequest: {
                    id: patternedRecurrenceId,
                    patternedRecurrenceDaysofWeekRequest: [
                      {
                        id: 0,
                        dayOfWeekId: dayOfWeekIds[day],
                      },
                    ],
                  },
                },
              };
            }
          }
        );
      }
    }

    const newState = Object.values(groupedTimeData);
    return newState;
  };

  const formatHoursData = () => {
    const updatedTimeData: { [key: string]: TimeRange[] } = dayNames.reduce((acc, day) => {
      acc[day] = [];
      return acc;
    }, {} as { [key: string]: TimeRange[] });

    vendorLocationStore.quarantineAirportHoursList.forEach(item => {
      const { schedule, id: hoursId } = item;
      const { patternedRecurrence } = schedule;
      patternedRecurrence.patternedRecurrenceDaysofWeek.forEach(day => {
        const dayName = day.dayOfWeek.name;
        if (updatedTimeData[dayName]) {
          const startTime = new Date(schedule.startTime).toISOString().slice(11, 16);
          const endTime = new Date(schedule.endTime).toISOString().slice(11, 16);

          updatedTimeData[dayName].push({
            id: `original-${dayName}-${startTime}-${endTime}`,
            startTime,
            endTime,
            is24Hours: Boolean(
              moment(schedule.startTime)
                .utc()
                .format('HH:mm') === '00:01' &&
                moment(schedule.endTime)
                  .utc()
                  .format('HH:mm') === '23:59'
            ),
            hoursId,
            scheduleId: schedule.id,
            patternedRecurrenceId: patternedRecurrence.id,
            isNew: false,
            sequence: item.sequence,
            hoursTypeId: item.hoursType.id,
            statusId: item.status.id,
            accessLevelId: item.accessLevel.id,
            startDate: schedule.startDate,
            endDate: schedule.endDate,
            includeHoliday: schedule.includeHoliday,
            dayOfWeekId: dayOfWeekIds[dayName],
            patternedRecurrenceDaysofWeekId: day.id,
            active: true,
          });
        }
      });
    });
    vendorLocationStore.overTimeHoursData = groupDaysByTimeRange(updatedTimeData).filter(item => {
      const startTime = moment(item.scheduleRequest.startTime)
        .utc()
        .format('HH:mm');
      const endTime = moment(item.scheduleRequest.endTime)
        .utc()
        .format('HH:mm');
      return !(startTime === '00:00' && endTime === '00:00') && item.id !== 0;
    });
  };

  const onOvertimeValueChange = value => {};

  return (
    <div className={classes.mainTerminalOnlyWrapper}>
      <Box>
        <CustomAccordion
          panelName="vipAreaTerminalOperationalHours"
          panelHeading="VIP Area/Terminal - Operational Hours (in local time)"
          panelContent={
            <MainTerminalBothOperationalHours
              handleSave={handleSave}
              locationHoursList={vendorLocationStore.airportHoursList}
            />
          }
        />
      </Box>
      <Box>
        <CustomAccordion
          panelName="vipAreaTerminalCost"
          panelHeading="VIP Area/Terminal Cost"
          panelBodyHeading="What is the cost for using the VIP Area/Terminal, at the Main Terminal?"
          panelContent={
            <FormControl variant="outlined">
              <OutlinedInput
                className="inputTextField"
                id="outlined-adornment-terminal-cost"
                value={vendorLocationStore.priceValue}
                onChange={handleInputValue}
                aria-describedby="outlined-terminal-cost-text"
                inputProps={{
                  'aria-label': 'terminal cost',
                }}
                labelWidth={0}
                style={{
                  borderColor: error ? 'red' : 'initial',
                  borderWidth: '1px',
                  borderStyle: error ? 'solid' : 'none',
                }}
              />
              {error && <FormHelperText style={{ color: 'red' }}>Please enter only numeric values.</FormHelperText>}
            </FormControl>
          }
        />
      </Box>
      <Box>
        <CustomAccordion
          panelName="vipAreaFeeApplication"
          panelHeading="VIP Area/Terminal Fee application"
          panelBodyHeading="Is this fee person, or per facility use?"
          panelContent={
            <AccordionRadioGroups
              radioValue={vendorLocationStore.feeValue}
              onRadioChange={onFeeApplicationValueChange}
              radioGroupData={areaTerminalApplicationData}
              setRadioValue={setFeeValue}
            />
          }
        />
      </Box>
      <Box>
        <CustomAccordion
          panelName="vipAreaOvertime"
          panelHeading="VIP Area/Terminal Overtime"
          panelBodyHeading="Is overtime possible?"
          panelContent={
            <AccordionRadioGroups
              radioValue={overTimeValue}
              onRadioChange={onOvertimeValueChange}
              setRadioValue={setOverTimeValue}
              radioGroupData={isOvertimePossibleData}
              isOverTime={true}
            />
          }
        />
      </Box>
    </div>
  );
};

export default inject(
  'vendorLocationStore',
  'serviceItemPricingStore',
  'settingsStore'
)(withStyles(styles)(observer(MainTerminalBoth)));
