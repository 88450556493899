import { action, observable } from 'mobx';
import { apiUrls } from '../API.url';
import { map, tap } from 'rxjs/operators';
import { Logger } from '@uplink-shared/security';
import { baseApiPath, HttpClient } from '@uplink/shared';
import { Observable } from 'rxjs';
import { vendorManagementHeaders } from '../Base.store';
import { IAPIGridRequest, IAPIPageResponse, Utilities } from '@wings-shared/core';
import { VendorOnBoardSlideOneNTwoModel } from '../../Modules/Shared';
import { AlertStore } from '@uvgo-shared/alert';
import { RootDataStore } from '@uplink-shared/layout';

export class SlideOneAndTwoStore {
  @observable public hasDataLoaded: boolean = true;
  @observable public operationalEssentialDataChanged: boolean = false;
  @observable public isCreditFieldValid: boolean = true;
  @observable public isOperationTypeFieldExist: boolean = true;
  @observable public isEndDateRequired: boolean = false;
  @observable public isNotesTextExist: boolean = true;
  @observable public tempLocationId: string = '';
  @observable public toggleSwitch: boolean = false;
  @observable public formValueChanged: boolean = false;

  @action
  public getById(id: number, forceRefresh?: boolean): Observable<IAPIPageResponse<VendorOnBoardSlideOneNTwoModel>> {
    const http: HttpClient = new HttpClient({ headers: vendorManagementHeaders });
    return http.get<any>(`${baseApiPath.vendorManagementCoreUrl}/${apiUrls.vendorOnBoardSlideOneNTwo}/${id}`).pipe(
      Logger.observableCatchError,
      map(response => {
        return { ...response };
      })
    );
  }

  @action
  public getByVendorId(vendorId?: number): Observable<IAPIPageResponse<VendorOnBoardSlideOneNTwoModel>> {
    const http: HttpClient = new HttpClient({ headers: vendorManagementHeaders });
    const locationUniqueCodeQuery = RootDataStore.locationData.locationUniqueCode
      ? `&locationUniqueCode=${RootDataStore.locationData.locationUniqueCode}`
      : '';
    return http
      .get<any>(
        `${baseApiPath.vendorManagementCoreUrl}/${apiUrls.vendorOnBoardSlideOneNTwoGetByVendorId}?vendorId=${vendorId}&${locationUniqueCodeQuery}`
      )
      .pipe(
        Logger.observableCatchError,
        map(response => {
          return { ...response };
        })
      );
  }

  @action
  public upsertSlideOneNTwo(
    payload: VendorOnBoardSlideOneNTwoModel,
    operationTypeId?: number
  ): Observable<VendorOnBoardSlideOneNTwoModel> {
    const http = new HttpClient({ headers: vendorManagementHeaders });
    payload.id = payload.id === null ? 0 : payload.id;
    const isNewRequest: boolean = !(payload.id != null && payload.id !== 0);
    const upsertRequest: Observable<VendorOnBoardSlideOneNTwoModel> = isNewRequest
      ? http.post<any>(`${baseApiPath.vendorManagementCoreUrl}/${apiUrls.vendorOnBoardSlideOneNTwo}`, payload)
      : http.put<any>(
        `${baseApiPath.vendorManagementCoreUrl}/${apiUrls.vendorOnBoardSlideOneNTwo}/${payload.id}`,
        payload
      );
    return upsertRequest.pipe(
      Logger.observableCatchError,
      tap(() =>
        operationTypeId
          ? AlertStore.info('Slide two data saved successfully!')
          : AlertStore.info('Slide one data saved successfully!')
      ),
      map(response => response)
    );
  }

  @action
  public getByVendorOnboardTracking(
    tempLocationId: string
  ): Observable<IAPIPageResponse<[VendorOnBoardSlideOneNTwoModel]>> {
    const http: HttpClient = new HttpClient({ headers: vendorManagementHeaders });
    return http
      .get<any>(`${baseApiPath.vendorManagementCoreUrl}/${apiUrls.vendorOnboardTracking}/${tempLocationId}`)
      .pipe(
        Logger.observableCatchError,
        map(response => {
          return [ ...response ];
        })
      );
  }
}
