import { CoreModel, ISelectOption, modelProtection } from '@wings-shared/core';
import { SettingBaseModel } from './SettingBase.model';
import { ScheduleModel } from './Schedule.model';
import { SCHEDULE_TYPE } from '@wings-shared/scheduler';
import { RootDataStore } from '@uplink-shared/layout';
import moment from 'moment';
import { Airports } from './Airports.model';

@modelProtection
export class LocationHoursModel extends CoreModel {
  id: number = 0;
  sequence: number;
  airportReferenceId: number;
  hoursType: SettingBaseModel = new SettingBaseModel();
  airportHoursType: SettingBaseModel = new SettingBaseModel();
  hoursScheduleType: SettingBaseModel = new SettingBaseModel();
  airportHoursScheduleType: SettingBaseModel = new SettingBaseModel();
  airportHoursSubType: SettingBaseModel = new SettingBaseModel();
  status: SettingBaseModel = new SettingBaseModel();
  accessLevel: SettingBaseModel = new SettingBaseModel();
  schedule: ScheduleModel = new ScheduleModel();
  airportReference: Airports = new Airports();

  constructor(data?: Partial<LocationHoursModel>) {
    super(data);
    Object.assign(this, data);
    this.schedule = data?.schedule || new ScheduleModel();
  }

  static deserialize(apiData: LocationHoursModel): LocationHoursModel {
    if (!apiData) {
      return new LocationHoursModel();
    }
    const data: Partial<LocationHoursModel> = {
      ...apiData,
      sequence: apiData.sequence,
      hoursType: SettingBaseModel.deserialize(apiData.hoursType || apiData.airportHoursType),
      hoursScheduleType: SettingBaseModel.deserialize(apiData.hoursScheduleType || apiData.airportHoursScheduleType),
      status: SettingBaseModel.deserialize(apiData.status),
      accessLevel: SettingBaseModel.deserialize(apiData.accessLevel),
      schedule: ScheduleModel.deserialize(apiData.schedule),
      airportReference: Airports.deserializeAirportReference(apiData?.airportReference),
    };
    return new LocationHoursModel(data);
  }

  static deserializeList(apiDataList: LocationHoursModel[]): LocationHoursModel[] {
    return apiDataList ? apiDataList.map((apiData: any) => LocationHoursModel.deserialize(apiData)) : [];
  }

  public serialize(locationId: number) {
    return {
      id: this.id || 0,
      userId: '',
      vendorLocationId: locationId,
      sequence: parseInt(this.sequence),
      hoursTypeId: this.hoursType.id,
      hoursScheduleTypeId: 1,
      statusId: this.status.id,
      accessLevelId: this.accessLevel.id,
      scheduleRequest: this.schedule.serialize(this.isRecurring),
    };
  }

  static airportHoursSerializeList(
    hoursData: LocationHoursModel[],
    hoursTypeId: number,
    hoursSubTypeId: number
  ): LocationHoursModel[] {
    return hoursData.length > 0
      ? hoursData.map((data: LocationHoursModel) => {
        return LocationHoursModel.airportHoursSerialize(data, hoursTypeId, hoursSubTypeId);
      })
      : [];
  }

  static hoursSerializeList(hoursData: LocationHoursModel[], hoursTypeId: number): LocationHoursModel[] {
    return hoursData.length > 0
      ? hoursData.map((data: LocationHoursModel) => {
        return LocationHoursModel.hoursSerialize(data, hoursTypeId);
      })
      : [];
  }

  static airportHoursSerialize(data, hoursTypeId: number, hoursSubTypeId: number) {
    return {
      id: data.id || 0,
      userId: '',
      vendorLocationId: RootDataStore.locationData.locationId,
      sequence: data.sequence || 0,
      airportReferenceId: RootDataStore.locationData.airportReferenceId,
      hoursTypeId: hoursTypeId,
      airportHoursTypeId: hoursTypeId,
      airportHoursSubTypeId: hoursSubTypeId,
      hoursScheduleTypeId: 1,
      statusId: data.statusId,
      accessLevelId: data.accessLevelId,
      airportHoursScheduleRequest: {
        id: data.scheduleRequest.id,
        startDate: data.scheduleRequest.startDate || null,
        endDate: data.scheduleRequest.endDate || null,
        startTime: data.scheduleRequest.startTime,
        endTime: data.scheduleRequest.endTime,
        is24Hours: Boolean(
          moment(data.scheduleRequest.startTime)
            .utc()
            .format('HH:mm') === '00:01' &&
            moment(data.scheduleRequest.endTime)
              .utc()
              .format('HH:mm') === '23:59'
        ),
        includeHoliday: data.scheduleRequest.includeHoliday || false,
        patternedRecurrenceRequest: {
          id: data.scheduleRequest.patternedRecurrenceRequest.id,
          patternedRecurrenceDaysofWeekRequest: data.scheduleRequest.patternedRecurrenceRequest
            ? data.scheduleRequest.patternedRecurrenceRequest?.patternedRecurrenceDaysofWeekRequest.map(
                (item: any) => ({
                  id: 0,
                  dayOfWeekId: item.dayOfWeekId,
                })
              )
            : [],
        },
      },
    };
  }

  static hoursSerialize(data, hoursTypeId: number) {
    return {
      id: data.id || 0,
      userId: '',
      vendorLocationId: RootDataStore.locationData.locationId,
      sequence: data.sequence || 0,
      airportReferenceId: RootDataStore.locationData.airportReferenceId,
      hoursTypeId: hoursTypeId,
      hoursScheduleTypeId: 1,
      statusId: data.statusId,
      accessLevelId: data.accessLevelId,
      scheduleRequest: {
        id: data.scheduleRequest.id,
        startDate: data.scheduleRequest.startDate || null,
        endDate: data.scheduleRequest.endDate || null,
        startTime: data.scheduleRequest.startTime,
        endTime: data.scheduleRequest.endTime,
        is24Hours: Boolean(
          moment(data.scheduleRequest.startTime)
            .utc()
            .format('HH:mm') === '00:01' &&
            moment(data.scheduleRequest.endTime)
              .utc()
              .format('HH:mm') === '23:59'
        ),
        includeHoliday: data.scheduleRequest.includeHoliday || false,
        patternedRecurrenceRequest: {
          id: data.scheduleRequest.patternedRecurrenceRequest?.id,
          patternedRecurrenceDaysofWeekRequest: data.scheduleRequest.patternedRecurrenceRequest
            ? data.scheduleRequest.patternedRecurrenceRequest?.patternedRecurrenceDaysofWeekRequest.map(
                (item: any) => ({
                  id: 0,
                  dayOfWeekId: item.dayOfWeekId,
                })
              )
            : [],
        },
      },
    };
  }

  public get isRecurring(): boolean {
    return this.hoursScheduleType?.id === SCHEDULE_TYPE.RECURRENCE;
  }

  // required in auto complete
  public get label(): string {
    return `${this.id}`;
  }

  public get value(): string | number {
    return this.id;
  }
}
