import React, { FC, useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { Box, FormControl, OutlinedInput, withStyles } from '@material-ui/core';
import { useGridState } from '@uplink-shared/custom-ag-grid';
import CustomAccordion from '../../../../../Shared/Components/CustomAccordion/CustomAccordion';
import MainOperationalHours from '../MainTerminalOnly/MainOperationalHours/MainOperationalHours';
import AccordionRadioGroups from '../../../../../Shared/Components/RadioGroup/AccordionRadioGroups';
import { finalize, takeUntil, catchError, mergeMap } from 'rxjs/operators';
import { forkJoin, of } from 'rxjs';
import { AlertStore } from '@uvgo-shared/alert';
import { useUnsubscribe } from '@wings-shared/hooks';
import { IAPIGridRequest, IClasses, UIStore } from '@wings-shared/core';
import { VendorLocationStore } from '../../../../../../Stores';
import { LocationHoursModel } from '../../../../../Shared/Models/LocationHours.model';
import { ModalStore } from '@uvgo-shared/modal-keeper';
import { RootDataStore } from '@uplink-shared/layout';
import { styles } from '../../AirportHours.styles';

interface Props {
  classes?: IClasses;
  vendorLocationStore: VendorLocationStore;
}

const GatBuilding: FC<Props> = ({ classes, vendorLocationStore }) => {
  const gridState = useGridState();
  const [ inputValue, setInputValues ] = useState('');
  const unsubscribe = useUnsubscribe();

  useEffect(() => {
    loadInitialData();
  }, []);

  const loadInitialData = () => {
    vendorLocationStore.isTimeChanged = false;
    UIStore.setPageLoader(true);
    const request: IAPIGridRequest = {
      pageNumber: gridState.pagination.pageNumber,
      pageSize: gridState.pagination.pageSize,
      filterCollection: JSON.stringify([
        {
          propertyName: 'AirportReference.Id',
          propertyValue: RootDataStore.locationData.airportReferenceId,
        },
        {
          propertyName: 'AirportHoursType.Id',
          propertyValue: 1,
          operator: 'and',
        },
        {
          propertyName: 'AirportHoursScheduleType.Id',
          propertyValue: 1,
          operator: 'and',
        },
        {
          propertyName: 'AirportHoursSubType.Id',
          propertyValue: 21,
          operator: 'and',
        },
      ]),
    };
    vendorLocationStore.getAirportHours(request).subscribe(() => {
      UIStore.setPageLoader(false);
      ModalStore.close();
    });
  };

  const handleInputValue = event => {
    setInputValues(event.target.value);
  };

  const gatFeeApplicationData = [
    { id: 1, value: 'per person', label: 'Per person, per use (arrival or departure)' },
    { id: 2, value: 'per facility', label: 'Per facility use (regardless of pax count)' },
  ];

  const errorHandler = (errors: { [key: string]: string[] }): void => {
    Object.keys(errors).forEach(key => {
      const errorMessages = errors[key];
      errorMessages.forEach(message => {
        AlertStore.info(message);
      });
    });
  };

  const handleErrorResponse = error => {
    if (error.response?.data?.errors) {
      errorHandler(error.response?.data?.errors);
      return;
    }
    if (error?.message) {
      AlertStore.info(error?.message);
    }
  };

  const deleteAllRecords = () => {
    const ids = vendorLocationStore.timeDataHoursData && vendorLocationStore.timeDataHoursData.map(item => item.id);
    if(ids.length === 0){
      ModalStore.close();
      return;
    }
    UIStore.setPageLoader(true);
    vendorLocationStore
      ?.deleteAirportHours(ids)
      .pipe(
        takeUntil(unsubscribe.destroy$),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe({
        next: (response: LocationHoursModel[]) => {
          AlertStore.info('Airport Hours saved successfully!');
          loadInitialData();
          ModalStore.close();
        },
        error: error => {
          handleErrorResponse(error);
        },
      });
  };

  const handleSave = () => {
    const hoursIdZeroData = vendorLocationStore.updatedHoursData.filter(item => item.id === 0);
    const hoursIdOtherData = vendorLocationStore.updatedHoursData.filter(item => item.id !== 0);

    if(hoursIdZeroData.length === 0 && hoursIdOtherData.length === 0){
      deleteAllRecords();
      return;
    }

    const apiCalls = [];

    UIStore.setPageLoader(true);
    apiCalls.push(
      vendorLocationStore
        ?.updateAirportHours(LocationHoursModel.airportHoursSerializeList(hoursIdOtherData, 1, 21))
        .pipe(
          mergeMap(() => {
            return forkJoin(
              vendorLocationStore?.addAirportHours(LocationHoursModel.airportHoursSerializeList(hoursIdZeroData, 1, 21))
            );
          })
        )
        .pipe(
          catchError(error => {
            handleErrorResponse(error);
            return of(null);
          })
        )
        .pipe(
          takeUntil(unsubscribe.destroy$),
          finalize(() => UIStore.setPageLoader(false))
        )
        .subscribe(responses => {
          const allSuccessful = responses && responses.every(response => response !== null);

          if (allSuccessful) {
            AlertStore.info('Airport Hours saved successfully!');
            loadInitialData();
            ModalStore.close();
          }
        })
    );
  };

  return (
    <div className={classes.mainTerminalOnlyWrapper}>
      <Box>
        <CustomAccordion
          panelName="gatOperationalHours"
          panelHeading="General Aviation Terminal (GAT) - Operational Hours (in local time)"
          panelContent={
            <MainOperationalHours handleSave={handleSave} locationHoursList={vendorLocationStore.airportHoursList} />
          }
        />
      </Box>
      <Box>
        <CustomAccordion
          panelName="gatCost"
          panelHeading="GAT Cost"
          panelBodyHeading="What is the cost for using the General Aviation Terminal? (In your local currency)"
          panelContent={
            <FormControl variant="outlined">
              <OutlinedInput
                className="inputTextField"
                id="outlined-adornment-gat-cost"
                value={inputValue}
                onChange={handleInputValue}
                aria-describedby="outlined-terminal-cost-text"
                inputProps={{
                  'aria-label': 'gat cost',
                }}
                labelWidth={0}
              />
            </FormControl>
          }
        />
      </Box>
      <Box>
        <CustomAccordion
          panelName="gatFeeApplication"
          panelHeading="GAT Fee application"
          panelBodyHeading="Is this fee person, or per facility use?"
          panelContent={<AccordionRadioGroups radioGroupData={gatFeeApplicationData} />}
        />
      </Box>
    </div>
  );
};

export default inject('vendorLocationStore')(withStyles(styles)(observer(GatBuilding)));
