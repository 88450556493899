import React, { FC, useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { newVendorOnboarding } from '../../../Shared/Components/SidebarMenu/SidebarMenu';
import { styles } from './Slide3.styles';
import { SidebarStore } from '@uplink-shared/layout';
import { withStyles, Grid, Avatar, Box, Typography } from '@material-ui/core';
import { IClasses, UIStore } from '@wings-shared/core';
import { SlideOneAndTwoStore, SlideThreeStore, VendorLocationStore } from '../../../../Stores';
import { PrimaryButton } from '@uvgo-shared/buttons';
import { UploadIcon } from '@uvgo-shared/icons';
import DescriptionIcon from '@material-ui/icons/Description';
import { finalize, takeUntil } from 'rxjs/operators';
import { AuthStore } from '@uplink-shared/security';
import { VendorOnBoardSlideOneNTwoModel } from '../../../Shared';
import { useUnsubscribe } from '@wings-shared/hooks';

interface Props {
  classes?: IClasses;
  slideThreeStore?: SlideThreeStore;
  registerSaveData: (saveData: () => void) => void;
  activeStep: number;
  vendorLocationStore: VendorLocationStore;
  setActiveStep: React.Dispatch<number>;
  slideOneAndTwoStore: SlideOneAndTwoStore;
}

const Slide3: FC<Props> = ({
  classes,
  registerSaveData,
  slideThreeStore,
  activeStep,
  setActiveStep,
  vendorLocationStore,
  slideOneAndTwoStore,
}) => {
  const unsubscribe = useUnsubscribe();

  const loadDocuments = () => {
    UIStore.setPageLoader(true);
    slideThreeStore
      ?.getDocumentsByOperationTypeId(vendorLocationStore.operationTypeId)
      .subscribe(() => UIStore.setPageLoader(false));
  };

  useEffect(() => {
    registerSaveData(saveData);
    return () => registerSaveData(null);
  }, []);

  const saveData = () => {};

  useEffect(() => {
    SidebarStore.setNavLinks(newVendorOnboarding(), 'vendor', 'Vendor Locations', '/vendor/locations');
    if (vendorLocationStore.operationTypeId) {
      loadDocuments();
    } else {
      if (AuthStore.vendorProfile?.id) {
        loadInitialData();
      }
    }
  }, [ activeStep, vendorLocationStore.operationTypeId ]);

  const loadInitialData = () => {
    UIStore.setPageLoader(true);
    slideOneAndTwoStore
      .getByVendorId(AuthStore.vendorProfile.id)
      .pipe(
        takeUntil(unsubscribe.destroy$),
        finalize(() => {
          UIStore.setPageLoader(false);
        })
      )
      .subscribe((response: VendorOnBoardSlideOneNTwoModel) => {
        if (response[0]) {
          if (response[0].operationType) {
            vendorLocationStore.operationTypeId = response[0].operationType?.id;
          } else {
            vendorLocationStore.operationTypeId = 0;
          }
        }
      });
  };

  return (
    <Grid container spacing={3} className={classes.editorWrapperContainer} xs={12}>
      {slideThreeStore?.newVendorDocuments.map((item, index) => {
        return (
          <Grid container spacing={6} className={classes.innerGrid} xs={12} direction="row">
            <Grid item xs={5} style={{ display: 'flex', alignItems: 'center' }}>
              <Avatar
                style={{
                  backgroundColor: '#2C313D',
                  fontSize: '14px',
                  color: '#FFFFFF',
                  fontWeight: 600,
                  height: 53,
                  width: 53,
                  marginRight: '20px',
                }}
              >
                <DescriptionIcon />
              </Avatar>
              <Typography className={classes.heading}>{item.documentType.name}</Typography>
            </Grid>
            <Grid item xs={5}>
              <label>{item.documentType?.description}</label>
            </Grid>
            <Grid item xs={2}>
              <Box className={`${classes.primaryButton}`}>
                <PrimaryButton variant="contained" startIcon={<UploadIcon />}>
                  Upload document
                </PrimaryButton>
              </Box>
            </Grid>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default inject(
  'slideThreeStore',
  'vendorLocationStore',
  'slideOneAndTwoStore'
)(withStyles(styles)(observer(Slide3)));
