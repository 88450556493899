import React, { useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';

const throttle = (func, limit) => {
  let lastFunc;
  let lastRan;

  return (...args) => {
    if (!lastRan) {
      func(...args);
      lastRan = Date.now();
    } else {
      clearTimeout(lastFunc);
      lastFunc = setTimeout(() => {
        if (Date.now() - lastRan >= limit) {
          func(...args);
          lastRan = Date.now();
        }
      }, limit - (Date.now() - lastRan));
    }
  };
};

const InfiniteScroll = ({
  children,
  element: Element = 'div',
  hasMore = false,
  initialLoad = true,
  isReverse = false,
  loader = null,
  loadMore,
  pageStart = 0,
  refProp = null,
  getScrollParent = null,
  threshold = 250,
  useCapture = false,
  useWindow = true,
  resetPagination = false,
  throttleDelay = 200, // Default throttle delay
}) => {
  const scrollComponentRef = useRef(null);
  const pageLoaded = useRef(pageStart);
  const beforeScrollHeight = useRef(0);
  const beforeScrollTop = useRef(0);
  const loadMoreFlag = useRef(false);
  const options = useRef(null);

  const isPassiveSupported = useCallback(() => {
    let passive = false;
    const testOptions = {
      get passive() {
        passive = true;
        return false;
      },
    };

    try {
      window.addEventListener('test', null, testOptions);
      window.removeEventListener('test', null, testOptions);
    } catch (e) {
      // ignore
    }

    return passive;
  }, []);

  const eventListenerOptions = useCallback(() => {
    return isPassiveSupported() ? { useCapture, passive: true } : { passive: false };
  }, [ useCapture, isPassiveSupported ]);

  useEffect(() => {
    options.current = eventListenerOptions();
    attachScrollListener();

    return () => {
      detachScrollListener();
      detachMousewheelListener();
    };
  }, [ eventListenerOptions ]);

  useEffect(() => {
    if (isReverse && loadMoreFlag.current) {
      const parentElement = getParentElement(scrollComponentRef.current);
      parentElement.scrollTop = parentElement.scrollHeight - beforeScrollHeight.current + beforeScrollTop.current;
      loadMoreFlag.current = false;
    }
    attachScrollListener();
  });

  useEffect(() => {
    if (resetPagination) {
      pageLoaded.current = pageStart;
    }
  }, [ resetPagination, pageStart ]);

  const getParentElement = useCallback(
    el => {
      const scrollParent = getScrollParent && getScrollParent();
      return scrollParent ? scrollParent : el && el.parentNode;
    },
    [ getScrollParent ]
  );

  const detachMousewheelListener = useCallback(() => {
    const scrollEl = useWindow ? window : scrollComponentRef.current?.parentNode;
    scrollEl?.removeEventListener('mousewheel', mousewheelListener, options.current);
  }, [ useWindow ]);

  const detachScrollListener = useCallback(() => {
    const scrollEl = useWindow ? window : getParentElement(scrollComponentRef.current);
    scrollEl?.removeEventListener('scroll', throttledScrollListener.current, options.current);
    scrollEl?.removeEventListener('resize', throttledScrollListener.current, options.current);
  }, [ useWindow, getParentElement ]);

  const scrollListener = useCallback(() => {
    const el = scrollComponentRef.current;
    const parentNode = getParentElement(el);
    let offset;

    if (useWindow) {
      const scrollTop = window.pageYOffset !== undefined ? window.pageYOffset : document.documentElement.scrollTop;
      offset = isReverse ? scrollTop : calculateOffset(el, scrollTop);
    } else {
      offset = isReverse ? parentNode.scrollTop : el.scrollHeight - parentNode.scrollTop - parentNode.clientHeight;
    }

    if (offset < threshold && el?.offsetParent !== null) {
      detachScrollListener();
      beforeScrollHeight.current = parentNode.scrollHeight;
      beforeScrollTop.current = parentNode.scrollTop;

      if (typeof loadMore === 'function') {
        loadMore(++pageLoaded.current);
        loadMoreFlag.current = true;
      }
    }
  }, [ useWindow, isReverse, getParentElement, detachScrollListener, threshold, loadMore ]);

  const mousewheelListener = useCallback(
    e => {
      if (e.deltaY === 1 && !isPassiveSupported()) {
        e.preventDefault();
      }
    },
    [ isPassiveSupported ]
  );

  const attachScrollListener = useCallback(() => {
    if (!hasMore) return;

    const parentElement = getParentElement(scrollComponentRef.current);
    const scrollEl = useWindow ? window : parentElement;

    scrollEl?.addEventListener('mousewheel', mousewheelListener, options.current);
    scrollEl?.addEventListener('scroll', throttledScrollListener.current, options.current);
    scrollEl?.addEventListener('resize', throttledScrollListener.current, options.current);

    if (initialLoad) {
      scrollListener();
    }
  }, [ hasMore, useWindow, mousewheelListener, scrollListener, initialLoad, getParentElement ]);

  const calculateOffset = (el, scrollTop) => {
    if (!el) return 0;
    return calculateTopPosition(el) + (el.offsetHeight - scrollTop - window.innerHeight);
  };

  const calculateTopPosition = el => {
    if (!el) return 0;
    return el.offsetTop + calculateTopPosition(el.offsetParent);
  };

  const renderLoader = () => {
    if (hasMore && loader) {
      return isReverse ? [ loader, children ] : [ children, loader ];
    }
    return children;
  };

  // Throttled scroll listener
  const throttledScrollListener = useRef(throttle(scrollListener, throttleDelay));

  return (
    <Element ref={scrollComponentRef} {...refProp}>
      {renderLoader()}
    </Element>
  );
};

InfiniteScroll.propTypes = {
  children: PropTypes.node.isRequired,
  element: PropTypes.node,
  hasMore: PropTypes.bool,
  initialLoad: PropTypes.bool,
  isReverse: PropTypes.bool,
  loader: PropTypes.node,
  loadMore: PropTypes.func.isRequired,
  pageStart: PropTypes.number,
  refProp: PropTypes.func,
  getScrollParent: PropTypes.func,
  threshold: PropTypes.number,
  useCapture: PropTypes.bool,
  useWindow: PropTypes.bool,
  throttleDelay: PropTypes.number, // Add throttleDelay to prop types
};

InfiniteScroll.defaultProps = {
  hasMore: false,
  initialLoad: true,
  isReverse: false,
  loader: null,
  pageStart: 0,
  refProp: null,
  getScrollParent: null,
  threshold: 250,
  useCapture: false,
  useWindow: true,
  throttleDelay: 200, // Default throttle delay
};

export default InfiniteScroll;
