import React, { FC, useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { Box, FormControlLabel, Radio, RadioGroup, withStyles } from '@material-ui/core';
import { styles } from '../../../OperationalInformation/Handler/OperationalHours/OperationalHours.styles';
import { VendorLocationStore } from '../../../../Stores';

interface Props {
  onRadioChange: () => void;
  divShowHide: boolean;
  radioGroupData: [];
  radioValue: number;
  setRadioValue?: React.Dispatch<number>;
  vendorLocationStore: VendorLocationStore;
  isOverTime?: boolean;
}

const AccordionRadioGroups: FC<Props> = ({
  onRadioChange,
  divShowHide,
  radioGroupData,
  radioValue,
  setRadioValue,
  vendorLocationStore,
  isOverTime
}) => {
  useEffect(() => {}, []);

  const checkQuarantineHours = event => {
    const value = Number(event.target.value);
    if(!isOverTime){
      vendorLocationStore.feeValue = value;
    }else {
      vendorLocationStore.overTimeValue = value;
    }
    setRadioValue(value);
    divShowHide && onRadioChange(value);
  };

  return (
    <div className="accordionRadioGroupsWrapper">
      <Box>
        <RadioGroup
          row
          aria-label="quarantineHours"
          name="quarantineHours"
          className="radioGroupWrapper"
          onChange={checkQuarantineHours}
          value={radioValue}
        >
          {radioGroupData.map((item, index) => (
            <FormControlLabel value={item?.id} control={<Radio color="primary" />} label={item.label} />
          ))}
        </RadioGroup>
      </Box>
    </div>
  );
};

export default inject('vendorLocationStore')(withStyles(styles)(observer(AccordionRadioGroups)));
