import React, { FC, useEffect, useRef, useState, ReactNode } from 'react';
import {
  GridPagination,
  IAPIGridRequest,
  IAPIPageResponse,
  UIStore,
  Utilities,
  cellStyle,
  IClasses,
  GRID_ACTIONS,
  regex,
  SearchStore,
} from '@wings-shared/core';
import { CircularProgress, Typography, withStyles } from '@material-ui/core';
import { Dialog } from '@uvgo-shared/dialog';
import { ModalStore } from '@uvgo-shared/modal-keeper';
import { PrimaryButton } from '@uvgo-shared/buttons';
import { styles } from './VendorContact.style';
import { ColDef, GridOptions, RowNode } from 'ag-grid-community';
import { BaseStore, SettingsStore, VendorManagementStore, VendorLocationStore, ContactMasterStore } from '../../Stores';
import { useUnsubscribe } from '@wings-shared/hooks';
import { SearchHeaderV2, ISearchHeaderRef } from '@uplink-shared/form-controls';
import { finalize, takeUntil } from 'rxjs/operators';
import { inject, observer } from 'mobx-react';
import { ConfirmDialog, ConfirmNavigate, SidebarStore } from '@uplink-shared/layout';
import { VendorAddressModel, VendorContactModel, ContactMasterModel } from '../Shared/Models';
import { COLLECTION_NAMES } from '../Shared/Enums/CollectionName.enum';
import {
  SETTING_ID,
  SettingBaseModel,
  VENDOR_ADDRESS_DATA_FILTER,
  VendorManagmentModel,
  VENDOR_LEVEL_COMPARISON_FILTERS,
  VendorModuleSecurity,
} from '../Shared';

import { addressGridFilters } from './Fields';
import { IAPIResponseVendorAddress } from '../Shared/Interfaces/Response/API-Response-VendorAddress';
import CustomHeader from '../Shared/Components/CustomHeader/CustomHeader';
import { AuthStore } from '@uplink-shared/security';
import { sidebarMenus } from '../Shared/Components/SidebarMenu/SidebarMenu';
import CustomButton from '../Shared/Components/CustomButton/CustomButton';
import { useNavigate, useParams } from 'react-router';
import { IAPIResponseVendorContact } from '../Shared/Interfaces/Response/API-Response-VendorContact';
import CustomList from '../Shared/Components/CustomList/CustomList';
import InfiniteScroll from '../Shared/Components/InfiniteScroll/InfiniteScroll';
import CustomDialog from '../Shared/Components/CustomDialog/CustomDialog';
import { useGridState } from '@uplink-shared/custom-ag-grid';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import CustomTooltip from '../Shared/Components/CustomTooltip/CustomTooltip';
import { MixPanelTrackingEvents } from '@uplink/shared';
import { AnalyticsStore } from '@uplink-shared/analytics';

interface Props {
  vendorManagementStore: VendorManagementStore;
  settingsStore: SettingsStore;
  contactMasterStore?: ContactMasterStore;
  vendorId: number;
  classes: IClasses;
  params?: { id: Number };
  placeHolder?: string
}

const VendorContact: FC<Props> = ({ 
  vendorManagementStore, vendorId, settingsStore, contactMasterStore, classes, placeHolder 
}) => {
  const unsubscribe = useUnsubscribe();
  const gridState = useGridState();
  const searchHeaderRef = useRef<ISearchHeaderRef>();
  const [ selectedVendor, setSelectedVendor ] = useState(new VendorManagmentModel());

  const navigate = useNavigate();

  useEffect(() => {
    SidebarStore.setNavLinks(sidebarMenus(), 'vendor');
    loadInitialData();
    contactMasterStore.hasDataLoaded = true;
    AnalyticsStore.track(MixPanelTrackingEvents.VENDOR_CONTACT);
  }, [ vendorId ]);

  const rightContent = (): ReactNode => {
    return (
      <CustomButton
        variant="contained"
        startIcon={<AddRoundedIcon />}
        to="/vendor/contact/upsert"
        title="Add Contact"
      />
    );
  };

  const getSearchValue = (): string => {
    const searchHeader = searchHeaderRef.current?.getFilters();
    if (!searchHeader) {
      return null;
    }
    return searchHeader.searchValue || '';
  };

  const setSearchData = (): void => {
    const searchHeaderFilter = searchHeaderRef.current.getFilters();
    SearchStore.searchData.set(location.pathname, {
      searchValue: searchHeaderFilter?.searchValue,
      selectInputsValues: searchHeaderFilter?.selectInputsValues,
      chipValue: searchHeaderFilter.chipValue,
      pagination: gridState.pagination,
    });
  };

  const loadInitialData = () => {
    UIStore.setPageLoader(true);
    vendorManagementStore
      ?.getVendorById(AuthStore?.vendorProfile?.id)
      .pipe(
        takeUntil(unsubscribe.destroy$),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe((response: VendorManagmentModel) => {
        setSelectedVendor(response);
      });
  };

  const deleteContact = (item: VendorContactModel): void => {
    UIStore.setPageLoader(true);
    contactMasterStore
      ?.upsertVendorContact(item.serialize(item.id, item.contact.id, 3, item.contactUsegeType.id))
      .pipe(
        takeUntil(unsubscribe.destroy$),
        finalize(() => {
          UIStore.setPageLoader(false);
        })
      )
      .subscribe({
        next: () => {
          gridState.setGridData([]);
          loadVendorContactData({ pageNumber: 1 });
        },
        error: error => {
          BaseStore.showAlert(error.message, item.id);
        },
      });
  };

  const getConfirmation = (item: VendorContactModel): void => {
    ModalStore.open(
      <CustomDialog
        title="Remove this Contact"
        message={'Are you sure you want to remove this Contact?'}
        yesButton="Remove this Contact"
        noButton="Cancel"
        onNoClick={() => ModalStore.close()}
        onYesClick={() => deleteContact(item)}
      />
    );
  };

  const loadVendorContactData = (pageRequest?: IAPIGridRequest) => {
    UIStore.setPageLoader(true);
    const contactRequest: IAPIGridRequest = {
      pageNumber: gridState.pagination.pageNumber,
      pageSize: gridState.pagination.pageSize,
      filterCollection: JSON.stringify([
        {
          propertyName: 'Vendor.Id',
          propertyValue: AuthStore?.vendorProfile?.id,
        },
      ]),
      ...pageRequest,
    };
    contactMasterStore
      ?.getVMSComparison(COLLECTION_NAMES.VENDOR_CONTACT, contactRequest)
      .pipe(
        takeUntil(unsubscribe.destroy$),
        finalize(() => {
          UIStore.setPageLoader(false);
        })
      )
      .subscribe((response: IAPIPageResponse<IAPIResponseVendorContact>) => {
        const results = VendorContactModel.deserializeList(response.results);
        if (results.length === 0) {
          contactMasterStore.hasDataLoaded = false;
          return;
        }
        if (response.pageNumber === 1) {
          gridState.setGridData([]);
        }
        gridState.setGridData([ ...gridState.data, ...filterResults(results) ]);
      });
  };

  const filterResults = (resultList: VendorContactModel[]): VendorContactModel[] => {
    const propertyValue = getSearchValue();
    contactMasterStore.hasDataLoaded = true;
    if (propertyValue) {
      const results = resultList.filter(res => {
        return (
          matchValues(res.contact.contactMethod.name, propertyValue) ||
          matchValues(res.contact.contact, propertyValue) ||
          matchValues(res.contact?.contactType.name, propertyValue) ||
          matchValues(res.contact?.contactName, propertyValue) ||
          matchValues(res.contactUsegeType.name, propertyValue) ||
          matchValues(res.contact?.title, propertyValue)
        );
      });
      return results;
    } else {
      return resultList;
    }
  };

  const matchValues = (source: string, target: string): boolean => {
    return source ? source.toLowerCase().includes(target.toLowerCase()) : false;
  };

  const colDefNew = [
    {
      headerName: 'Contact Method',
      field: 'contact.contactMethod',
    },
    {
      headerName: 'Contact Usage Type',
      field: 'contactUsegeType',
    },
    {
      headerName: 'Contact Type',
      field: 'contact.contactType',
    },
    {
      headerName: 'Contact Method',
      field: 'contact.contactMethod',
    },
    {
      headerName: 'Contact',
      field: 'contact.contact',
    },
    {
      headerName: 'Contact Method',
      field: 'contact.contactName',
    },
    {
      headerName: 'Title',
      field: 'contact.title',
    },
    {
      field: 'actionRenderer',
      headerName: '\u00A0\u00A0\u00A0\u00A0Edit\u00A0\u00A0\u00A0\u00A0\u00A0Delete',
    },
  ];

  const onEdit = item => {
    setSearchData()
    navigate(`upsert/${item?.contact?.id}/${item?.id}`);
  };

  return (
    <>
      <CustomHeader title={<CustomTooltip title={`${selectedVendor.name} Contacts`}/>} />
      <SearchHeaderV2
        placeHolder="Start typing to search"
        ref={searchHeaderRef}
        selectInputs={[]}
        onResetFilterClick={() => {
          gridState.setGridData([]);
          contactMasterStore.hasDataLoaded = true;
          loadVendorContactData({ pageNumber: 1 });
        }}
        rightContent={rightContent}
        onFilterChange={isInitEvent => {
          gridState.setGridData([]);
          contactMasterStore.hasDataLoaded = true;
          loadVendorContactData({ pageNumber: isInitEvent ? gridState.pagination.pageNumber : 1 });
        }}
        isLoading={UIStore.pageLoading}
      />
      <InfiniteScroll
        pageStart={0}
        loadMore={page => {
          const searchData = SearchStore.searchData.get(location.pathname);
          if (searchData) {
            searchHeaderRef.current?.setupDefaultFilters(searchData);
            SearchStore.clearSearchData(location.pathname);
          }
          setTimeout(() => {
            loadVendorContactData({ pageNumber: page });
          }, 200);
        }}
        hasMore={contactMasterStore.hasDataLoaded && !UIStore.pageLoading}
        loader={
          <div style={{ textAlign: 'center' }}>
            <CircularProgress />
          </div>
        }
        useWindow={false}
      >
        <CustomList
          classes={classes}
          colDef={colDefNew}
          rowData={gridState.data}
          isHeaderVisible={false}
          onEdit={item => onEdit(item)}
          onDelete={item => getConfirmation(item)}
          isContact={true}
          showDeleteButton={true}
          isLoading={UIStore.pageLoading}
        />
      </InfiniteScroll>
    </>
  );
};

export default inject(
  'vendorManagementStore',
  'settingsStore',
  'contactMasterStore',
  'vendorLocationStore'
)(withStyles(styles)(observer(VendorContact)));
