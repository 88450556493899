import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  warningIcon: {
    fontSize: 60,
    color: '#F2C12C',
  },
  dialog:{
    padding: '20px',
    textAlign: 'center'
  },
  title: {
    fontFamily: 'Open Sans',
    fontSize: '20px',
    fontWeight: 900,
    lineHeight: '27px',
    letterSpacing: '0em',
    textAlign: 'center',
    padding: '0px',
  },
  content: {
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '19px',
    letterSpacing: '0em',
    textAlign: 'center',
  },
  cancelButton: {
    textTransform: 'capitalize',
    color: '#1976D2',
    marginTop: '20px'
  },
  yesButton: {
    backgroundColor: '#1976D2',
    textTransform: 'capitalize',
    marginTop: '20px'
  },
});
