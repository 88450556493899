import React, { FC, ReactNode, useEffect, useRef, useState } from 'react';
import { IAPIGridRequest, IAPIPageResponse, IClasses, SearchStore, UIStore } from '@wings-shared/core';
import { useUnsubscribe } from '@wings-shared/hooks';
import { finalize, takeUntil } from 'rxjs/operators';
import { inject, observer } from 'mobx-react';
import { useNavigate } from 'react-router';
import { forkJoin } from 'rxjs';
import { useAgGrid, useGridState } from '@uplink-shared/custom-ag-grid';
import { RootDataStore, SidebarStore } from '@uplink-shared/layout';
import { ISearchHeaderRef, SearchHeaderV2 } from '@uplink-shared/form-controls';
import { CircularProgress } from '@material-ui/core';
import { BaseStore, VendorDocumentStore, VendorLocationStore, VendorManagementStore } from '../../../../Stores';
import { sidebarMenus } from '../../../Shared/Components/SidebarMenu/SidebarMenu';
import CustomButton from '../../../Shared/Components/CustomButton/CustomButton';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import CustomTooltip from '../../../Shared/Components/CustomTooltip/CustomTooltip';
import CustomHeader from '../../../Shared/Components/CustomHeader/CustomHeader';
import InfiniteScroll from '../../../Shared/Components/InfiniteScroll/InfiniteScroll';
import CustomList from '../../../Shared/Components/CustomList/CustomList';
import { VendorDocumentModel, VendorLocationModel } from '../../../Shared';
import { VENDOR_DOCUMENT_DATA_FILTER } from '../../../Shared/Enums/VendorDocument.enum';
import { gridFilters } from '../../../VendorDocuments/Fields';
import { COLLECTION_NAMES } from '../../../Shared/Enums/CollectionName.enum';
import { IAPIDownloadDocumentFile } from '../../../Shared/Interfaces/Request/API-Request-VendorDocument';
import { AlertStore } from '@uvgo-shared/alert';
import { ModalStore } from '@uvgo-shared/modal-keeper';
import CustomDialog from '../../../Shared/Components/CustomDialog/CustomDialog';
import { MixPanelTrackingEvents } from '@uplink/shared';
import { AnalyticsStore } from '@uplink-shared/analytics';

interface Props {
  vendorLocationStore: VendorLocationStore;
  vendorDocumentStore: VendorDocumentStore;
  classes: IClasses;
}

const LocationDocuments: FC<Props> = ({ vendorLocationStore, classes, vendorDocumentStore }) => {
  const unsubscribe = useUnsubscribe();
  const navigate = useNavigate();
  const searchHeaderRef = useRef<ISearchHeaderRef>();
  const [ selectedVendorLocation, setSelectedVendorLocation ] = useState(new VendorLocationModel());
  const gridState = useGridState();
  const agGrid = useAgGrid<VENDOR_DOCUMENT_DATA_FILTER, VendorDocumentModel>(gridFilters, gridState);

  useEffect(() => {
    AnalyticsStore.track(MixPanelTrackingEvents.VENDOR_LOCATION_DOCUMENT);
    SidebarStore.setNavLinks(sidebarMenus(), 'vendor');
    loadVendorLocationData();
    vendorDocumentStore.hasDataLoaded = true;
  }, []);

  const downloadFile = (data: VendorDocumentModel) => {
    UIStore.setPageLoader(true);
    vendorDocumentStore
      ?.downloadLocationDocumentFile(data.documentUri, data.id, RootDataStore.locationData.locationId)
      .pipe(
        takeUntil(unsubscribe.destroy$),
        finalize(() => {
          UIStore.setPageLoader(false);
        })
      )
      .subscribe({
        next: (response: IAPIDownloadDocumentFile) => {
          // const url = window.URL.createObjectURL(new Blob([ documentUploadStore.file ]));
          const link = document.createElement('a');
          link.href = response.documentUri;
          link.target = '_blank';
          if (data.otherName !== null) {
            link.download = data.otherName;
          } else {
            link.download = data.name.name;
          }
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        },
        error: error => {
          AlertStore.info(`Error Downloading ${error.message}`);
        },
      });
  };

  const loadVendorLocationData = () => {
    UIStore.setPageLoader(true);
    vendorLocationStore
      ?.getVendorLocationById(RootDataStore.locationData.locationId)
      .pipe(
        takeUntil(unsubscribe.destroy$),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe((response: VendorLocationModel) => {
        setSelectedVendorLocation(response);
      });
  };

  const loadInitialData = (pageRequest?: IAPIGridRequest) => {
    UIStore.setPageLoader(true);
    gridState.setIsProcessing(true);
    const request: IAPIGridRequest = {
      pageNumber: gridState.pagination.pageNumber,
      pageSize: gridState.pagination.pageSize,
      ...searchCollection(),
      ...agGrid.filtersApi.gridSortFilters(),
      ...pageRequest,
      filterCollection: JSON.stringify([
        {
          propertyName: 'VendorLocation.VendorLocationId',
          propertyValue: RootDataStore.locationData.locationId,
        },
        {
          propertyName: 'Status.Id',
          propertyValue: 4,
          filterType: 'ne',
        },
      ]),
    };
    forkJoin([ vendorDocumentStore.getVendorDocument(COLLECTION_NAMES.VENDOR_LOCATION_DOCUMENT, request) ])
      .pipe(
        takeUntil(unsubscribe.destroy$),
        finalize(() => {
          UIStore.setPageLoader(false);
          gridState.setIsProcessing(false);
        })
      )
      .subscribe((response: [IAPIPageResponse<IAPIResponseVendorDocument>]) => {
        const results = VendorDocumentModel.deserializeList(response[0].results);
        if (results.length === 0) {
          vendorDocumentStore.hasDataLoaded = false;
          return;
        }
        if (response[0].pageNumber === 1) {
          gridState.setGridData([]);
        }
        gridState.setGridData([ ...gridState.data, ...results ]);
      });
  };

  const colDefNew = [
    {
      headerName: 'Document Name',
      field: 'documentName.name',
    },
    {
      headerName: 'Other Name',
      field: 'otherName',
    },
    {
      headerName: 'Start Date',
      field: 'startDate',
    },
    {
      headerName: 'Expiration Date',
      field: 'endDate',
    },
    {
      headerName: 'Status',
      field: 'status.name',
    },
    {
      headerName: 'Last Updated',
      field: 'lastUpdated',
    },
    {
      headerName: 'Document URI',
      field: 'documentUri',
    },
    {
      field: 'actionRenderer',
      headerName: '\u00A0\u00A0\u00A0\u00A0Edit\u00A0\u00A0\u00A0\u00A0\u00A0Delete',
    },
  ];

  const rightContent = (): ReactNode => {
    return (
      <CustomButton
        variant="contained"
        startIcon={<AddRoundedIcon />}
        to="/vendor/location/documents/upsert"
        title="Add Document"
      />
    );
  };

  const searchCollection = (): IAPIGridRequest | null => {
    const propertyValue = getSearchValue();
    vendorDocumentStore.hasDataLoaded = true;
    if (propertyValue === '') {
      return null;
    }
    const filters = [
      {
        propertyName: 'DocumentName.Name',
        propertyValue: propertyValue,
        filterType: 'string',
      },
      {
        propertyName: 'OtherName',
        propertyValue: propertyValue,
        operator: 'or',
        filterType: 'string',
      },
      {
        propertyName: 'Status.Name',
        propertyValue: propertyValue,
        operator: 'or',
        filterType: 'string',
      },
    ];
    return {
      searchCollection: JSON.stringify(filters),
    };
  };

  const onEdit = item => {
    setSearchData()
    navigate(`upsert/${item?.id}`);
  };

  const getSearchValue = (): string => {
    const searchHeader = searchHeaderRef.current?.getFilters();
    if (!searchHeader) {
      return null;
    }
    return searchHeader.searchValue || '';
  };

  const setSearchData = (): void => {
    const searchHeaderFilter = searchHeaderRef.current.getFilters();
    SearchStore.searchData.set(location.pathname, {
      searchValue: searchHeaderFilter?.searchValue,
      selectInputsValues: searchHeaderFilter?.selectInputsValues,
      chipValue: searchHeaderFilter.chipValue,
      pagination: gridState.pagination,
    });
  };

  const upsertLocationDocument = (model: VendorDocumentModel): void => {
    gridState.setIsProcessing(true);
    UIStore.setPageLoader(true);
    vendorDocumentStore
      ?.upsertLocationDocument(model.serialize(model.documentUri, 0, RootDataStore.locationData.locationId, 4))
      .pipe(
        takeUntil(unsubscribe.destroy$),
        finalize(() => {
          UIStore.setPageLoader(false);
        })
      )
      .subscribe({
        next: (response: VendorDocumentModel) => {
          gridState.setGridData([]);
          loadInitialData({ pageNumber: 1 });
        },
        error: error => {
          BaseStore.showAlert(error.message, model.id);
        },
      });
  };

  const getConfirmation = (item: VendorDocumentModel): void => {
    ModalStore.open(
      <CustomDialog
        title="Remove this Document"
        message={'Are you sure you want to remove this Document?'}
        yesButton="Remove this Document"
        noButton="Cancel"
        onNoClick={() => ModalStore.close()}
        onYesClick={() => upsertLocationDocument(item)}
      />
    );
  };

  return (
    <>
      <CustomHeader
        title={
          <CustomTooltip
            title={`${selectedVendorLocation.name} Documents - ${selectedVendorLocation.headerLabel()}`}
          />
        }
      />
      <SearchHeaderV2
        placeHolder="Start typing to search"
        ref={searchHeaderRef}
        onExpandCollapse={agGrid.autoSizeColumns}
        selectInputs={[]}
        onResetFilterClick={() => {
          gridState.setGridData([]);
          vendorDocumentStore.hasDataLoaded = true;
          loadInitialData({ pageNumber: 1 });
        }}
        rightContent={rightContent}
        onFilterChange={isInitEvent => {
          gridState.setGridData([]);
          vendorDocumentStore.hasDataLoaded = true;
          loadInitialData({ pageNumber: isInitEvent ? gridState.pagination.pageNumber : 1 });
        }}
        isLoading={UIStore.pageLoading}
      />
      <InfiniteScroll
        pageStart={0}
        loadMore={page => {
          const searchData = SearchStore.searchData.get(location.pathname);
          if (searchData) {
            searchHeaderRef.current?.setupDefaultFilters(searchData);
            SearchStore.clearSearchData(location.pathname);
          }
          setTimeout(() => {
            loadInitialData({ pageNumber: page });
          }, 200);
        }}
        hasMore={vendorDocumentStore.hasDataLoaded && !UIStore.pageLoading}
        loader={
          <div style={{ textAlign: 'center' }}>
            <CircularProgress />
          </div>
        }
        useWindow={false}
      >
        <CustomList
          classes={classes}
          colDef={colDefNew}
          rowData={gridState.data}
          isHeaderVisible={false}
          onEdit={item => onEdit(item)}
          onDownload={item => downloadFile(item)}
          onDelete={item => getConfirmation(item)}
          showDeleteButton={true}
          isVendorLocationDocument={true}
          isLoading={UIStore.pageLoading}
        />
      </InfiniteScroll>
    </>
  );
};
export default inject('vendorLocationStore', 'settingsStore', 'vendorDocumentStore')(observer(LocationDocuments));
