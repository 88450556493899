import { IAPIFilterDictionary } from '@wings-shared/core';
import { VENDOR_USER_DATA_FILTER } from '../../Shared/Enums/VendorUser.enum';

export const gridFilters: IAPIFilterDictionary<VENDOR_USER_DATA_FILTER>[] = [
  {
    columnId: 'givenName',
    apiPropertyName: 'givenName',
    uiFilterType: VENDOR_USER_DATA_FILTER.GIVEN_NAME,
  },
  {
    columnId: 'surName',
    apiPropertyName: 'surName',
    uiFilterType: VENDOR_USER_DATA_FILTER.SUR_NAME,
  },
  {
    columnId: 'email',
    apiPropertyName: 'email',
    uiFilterType: VENDOR_USER_DATA_FILTER.EMAIL,
  },
  {
    columnId: 'userRole',
    apiPropertyName: 'userRole',
    uiFilterType: VENDOR_USER_DATA_FILTER.USER_ROLE,
  },
  {
    columnId: 'vendorUserLocation',
    apiPropertyName: 'vendorLocation',
    uiFilterType: VENDOR_USER_DATA_FILTER.LOCATION,
  }
]