import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core';
import { SidebarStore } from '@uplink-shared/layout';
import { sidebarMenus } from '../Shared/Components/SidebarMenu/SidebarMenu';
import VendorGeneralInformation from '../VendorGeneralInformation/VendorGeneralInformation';

const useStyles = makeStyles((theme) => ({
  vmsVendorsContainer:{
    backgroundColor:''
  }
}));

const CoreModule = () => {
  const classes = useStyles();

  useEffect(() => {
    SidebarStore.setNavLinks(sidebarMenus(), 'vendor');
  }, []);

  return (
    <div className={classes.vmsVendorsContainer}>
      <VendorGeneralInformation />
      
    </div>
  );
};

export default CoreModule;
