import { CoreModel, ISelectOption, modelProtection } from '@wings-shared/core';
import { Airports } from '../Airports.model';
import { VendorLocationCountryModel } from '../VendorLocationCountry.model';
import { SettingBaseModel } from '../SettingBase.model';
import { RootDataStore } from '@uplink-shared/layout';
import { AuthStore } from '@uplink-shared/security';

@modelProtection
export class OperationTypeDocumentList extends CoreModel implements ISelectOption {
  id: number = 0;
  isRequired: boolean = false;
  documentType: SettingBaseModel = new SettingBaseModel();
  status: SettingBaseModel = new SettingBaseModel(); 

  constructor(data?: Partial<OperationTypeDocumentList>) {
    super(data);
    Object.assign(this, data);
  }

  public serialize(): OperationTypeDocumentList {
    return {
      id: this.id || 0,
      userId: this.userId,
      
    };
  }

  static deserialize(apiData: OperationTypeDocumentList): OperationTypeDocumentList {
    if (!apiData) {
      return new OperationTypeDocumentList();
    }
    const data: Partial<OperationTypeDocumentList> = {
      ...apiData,
      documentType: SettingBaseModel.deserialize(apiData.documentType),
      status: SettingBaseModel.deserialize(apiData.status),
    };
    return new OperationTypeDocumentList(data);
  }

  static deserializeList(apiDataList: OperationTypeDocumentList[]): OperationTypeDocumentList[] {
    return apiDataList ? apiDataList.map(apiData => OperationTypeDocumentList.deserialize(apiData)) : [];
  }

  public get label(): string {
    return this.documentType.name;
  }

  public get value(): string | number {
    return this.id;
  }
}
