import React from 'react';
import { INavigationLink, RootDataStore } from '@uplink-shared/layout';
import {
  HandlerIcon,
  ClockIcon,
  AircraftDocIcon,
  PassengerIcon,
  NoteIcon,
  AirplaneIcon
} from '@uvgo-shared/icons';

export const sidebarMenuOperationalInfo = (): INavigationLink[] => [
  {
    to: '/vendor/location/operational-information/aircraft-logistics',
    title: 'Handler',
    expandedMenuBlock: 'panel2',
    icon: <HandlerIcon />,
    subMenuItem: [
      {
        to: '/vendor/location/operational-information/aircraft-logistics',
        title: 'Aircraft Logistics',
        isDisabled: !RootDataStore.locationData.locationId,
      },
      {
        to: '/vendor/location/operational-information/passenger-logistics',
        title: 'Passenger Logistics',
        isDisabled: !RootDataStore.locationData.locationId,
      },
      {
        to: '/vendor/location/operational-information/handler-bulletins',
        title: 'Notes',
        isDisabled: !RootDataStore.locationData.locationId,
      },
    ],
  }
];
