import React, { FC, ReactNode, useEffect, useRef } from 'react';
import { VendorLocationStore, VendorManagementStore } from '../../Stores';
import { inject, observer } from 'mobx-react';
import { CircularProgress, withStyles } from '@material-ui/core';
import { styles } from './VendorLocations.styles';
import { ISearchHeaderRef, SearchHeaderV2 } from '@uplink-shared/form-controls';
import CustomHeader from '../Shared/Components/CustomHeader/CustomHeader';
import { VendorLocationModel } from '../Shared';
import { agGridUtilities, useAgGrid, useGridState } from '@uplink-shared/custom-ag-grid';
import { IAPIGridRequest, IAPIPageResponse, IClasses, SearchStore, UIStore } from '@wings-shared/core';
import { VENDOR_LOCATION_COMPARISON_FILTERS, VENDOR_LOCATION_DATA_FILTER } from '../Shared/Enums/VendorLocation.enum';
import { gridFilters } from './Fields';
import CustomButton from '../Shared/Components/CustomButton/CustomButton';
import { AuthStore } from '@uplink-shared/security';
import { finalize, takeUntil } from 'rxjs/operators';
import { useUnsubscribe } from '@wings-shared/hooks';
import { IAPIVMSVendorLocationComparison } from '../Shared/Interfaces';
import { sidebarMenus } from '../Shared/Components/SidebarMenu/SidebarMenu';
import { RootDataStore, SidebarStore } from '@uplink-shared/layout';
import InfiniteScroll from '../Shared/Components/InfiniteScroll/InfiniteScroll';
import CustomList from '../Shared/Components/CustomList/CustomList';
import { useNavigate } from 'react-router';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import { MixPanelTrackingEvents } from '@uplink/shared';
import { AnalyticsStore } from '@uplink-shared/analytics';

type Props = {
  vendorManagementStore: VendorManagementStore;
  vendorLocationStore: VendorLocationStore;
  classes: IClasses;
};

const VendorLocations: FC<Props> = ({ vendorLocationStore, vendorManagementStore, classes }) => {
  const searchHeaderRef = useRef<ISearchHeaderRef>();
  const navigate = useNavigate();
  const gridState = useGridState();
  const agGrid = useAgGrid<VENDOR_LOCATION_DATA_FILTER, VendorLocationModel>(gridFilters, gridState);
  const unsubscribe = useUnsubscribe();

  useEffect(() => {
    RootDataStore.setLocation(
      RootDataStore.locationData.locationId ? true : false,
      RootDataStore.locationData.locationId,
      RootDataStore.locationData.locationName,
      RootDataStore.locationData.locationCode,
      RootDataStore.locationData.airportReferenceId
    );
    AnalyticsStore.track(MixPanelTrackingEvents.VENDOR_LOCATION_LIST);
    SidebarStore.setNavLinks(sidebarMenus(), 'vendor');
    vendorLocationStore.hasDataLoaded = true;
  }, []);

  const rightContent = (): ReactNode => {
    return (
      <div className={classes.buttonContainer}>
        <CustomButton
          variant="contained"
          startIcon={<AddRoundedIcon />}
          to="/vendor/locations/upsert"
          title="Add Location"
          onClick={() => {
            RootDataStore.setLocation(true, 0, '', '', 0);
          }}
        />
      </div>
    );
  };

  const searchCollection = (): IAPIGridRequest | null => {
    const propertyValue = getSearchValue();
    if (propertyValue === '') {
      vendorLocationStore.hasDataLoaded = true;
      return null;
    }
    const filters = [
      {
        propertyName: 'Name',
        propertyValue: propertyValue,
        filterType: 'string',
      },
      {
        propertyName: 'AirportReference.AirportName',
        propertyValue: propertyValue,
        filterType: 'string',
        operator: 'or',
      },
      {
        propertyName: 'AirportReference.ICAOCode',
        propertyValue: propertyValue,
        filterType: 'string',
        operator: 'or',
      },
      {
        propertyName: 'AirportReference.IATACode',
        propertyValue: propertyValue,
        filterType: 'string',
        operator: 'or',
      },
      {
        propertyName: 'AirportReference.FAACode',
        propertyValue: propertyValue,
        filterType: 'string',
        operator: 'or',
      },
      {
        propertyName: 'AirportReference.RegionalCode',
        propertyValue: propertyValue,
        filterType: 'string',
        operator: 'or',
      },
      {
        propertyName: 'AirportReference.DisplayCode',
        propertyValue: propertyValue,
        filterType: 'string',
        operator: 'or',
      },
      {
        propertyName: 'AirportReference.UWACode',
        propertyValue: propertyValue,
        filterType: 'string',
        operator: 'or',
      },
    ];
    return {
      searchCollection: JSON.stringify(filters),
    };
  };

  const getSearchValue = (): string => {
    const searchHeader = searchHeaderRef.current?.getFilters();
    if (!searchHeader) {
      return null;
    }
    return searchHeader.searchValue || '';
  };

  const setSearchData = (): void => {
    const searchHeaderFilter = searchHeaderRef.current.getFilters();
    SearchStore.searchData.set(location.pathname, {
      searchValue: searchHeaderFilter?.searchValue,
      selectInputsValues: searchHeaderFilter?.selectInputsValues,
      chipValue: searchHeaderFilter.chipValue,
      pagination: gridState.pagination,
    });
  };

  const loadInitialData = (pageRequest?: IAPIGridRequest) => {
    UIStore.setPageLoader(true);
    const request: IAPIGridRequest = {
      pageNumber: gridState.pagination.pageNumber,
      pageSize: gridState.pagination.pageSize,
      ...searchCollection(),
      ...agGrid.filtersApi.gridSortFilters(),
      ...pageRequest,
      filterCollection: JSON.stringify([
        {
          propertyName: 'Vendor.Id',
          propertyValue: AuthStore?.vendorProfile?.id,
        },
      ]),
    };
    vendorLocationStore
      .getVMSComparison(request)
      .pipe(
        takeUntil(unsubscribe.destroy$),
        finalize(() => {
          UIStore.setPageLoader(false);
        })
      )
      .subscribe((response: IAPIPageResponse<IAPIVMSVendorLocationComparison>) => {
        const results = VendorLocationModel.deserializeList(response.results);
        if (results.length === 0) {
          vendorLocationStore.hasDataLoaded = false;
          return;
        }
        if (response.pageNumber === 1) {
          gridState.setGridData([]);
        }
        gridState.setGridData([ ...gridState.data, ...results ]);
      });
  };

  const colDefNew = [
    {
      headerName: 'Code',
      field: [
        'airportReference.displayCode',
        'airportReference.icaoCode',
        'airportReference.uwaCode',
        'airportReference.faaCode',
        'airportReference.iataCode',
        'airportReference.regionalCode',
        'vendorLocationCityReference.stateReference.code',
      ],
    },
    {
      headerName: 'Name',
      field: 'name',
    },
    {
      headerName: 'Airport Reference',
      field: [ 'airportReference.airportName', 'vendorLocationCityReference.cityReference.name' ],
    },
    {
      headerName: 'Airport/City Code',
      field: [
        'airportReference.displayCode',
        'airportReference.icaoCode',
        'airportReference.uwaCode',
        'airportReference.faaCode',
        'airportReference.iataCode',
        'airportReference.regionalCode',
        'vendorLocationCityReference.countryReference.name',
      ],
    },
    {
      headerName: 'Airport Reference Name',
      field: 'airportReference.airportName',
    },
    {
      field: 'actionRenderer',
      headerName: '\u00A0\u00A0\u00A0\u00A0Edit\u00A0\u00A0\u00A0\u00A0',
    },
  ];

  const onEdit = (item, e) => {
    e.preventDefault();
    e.stopPropagation();
    setSearchData();
    RootDataStore.setLocation(true, item.id, item.name, item.code, item.airportReference?.id);
    navigate('general-information');
  };

  const handleLocationSelect = (locationId, locationName, locationCode, airportReferenceId) => {
    RootDataStore.locationData.isLocationSet && RootDataStore.locationData.locationId == locationId
      ? RootDataStore.setLocation(false, 0, '', '', 0)
      : RootDataStore.setLocation(true, locationId, locationName, locationCode, airportReferenceId);
    SidebarStore.setNavLinks(sidebarMenus(), 'vendor');
  };

  return (
    <>
      <CustomHeader title={`${AuthStore.vendorProfile?.name} Location List`} />
      <SearchHeaderV2
        placeHolder="Start typing to search"
        ref={searchHeaderRef}
        selectInputs={[
          agGridUtilities.createSelectOption(
            VENDOR_LOCATION_COMPARISON_FILTERS,
            VENDOR_LOCATION_COMPARISON_FILTERS.VENDOR_NAME,
            'defaultOption'
          ),
        ]}
        isUsingSelectInputs={false}
        onResetFilterClick={() => {
          gridState.setGridData([]);
          loadInitialData({ pageNumber: 1 });
        }}
        rightContent={rightContent}
        onFilterChange={isInitEvent => {
          gridState.setGridData([]);
          vendorLocationStore.hasDataLoaded = true;
          loadInitialData({ pageNumber: isInitEvent ? gridState.pagination.pageNumber : 1 });
        }}
      />
      <InfiniteScroll
        pageStart={0}
        loadMore={page => {
          const searchData = SearchStore.searchData.get(location.pathname);
          if (searchData) {
            searchHeaderRef.current?.setupDefaultFilters(searchData);
            SearchStore.clearSearchData(location.pathname);
          }
          setTimeout(() => {
            loadInitialData({ pageNumber: page });
          }, 200);
        }}
        hasMore={vendorLocationStore.hasDataLoaded && !UIStore.pageLoading}
        loader={
          <div style={{ textAlign: 'center' }}>
            <CircularProgress />
          </div>
        }
        useWindow={false}
      >
        <CustomList
          classes={classes}
          colDef={colDefNew}
          rowData={gridState.data}
          isHeaderVisible={false}
          onEdit={(item, e) => onEdit(item, e)}
          isContact={false}
          isLocation={true}
          showDeleteButton={false}
          isLocationSelected={item =>
            handleLocationSelect(item.id, item.name, item.code, item.airportReference?.id)
          }
          selectedItemId={RootDataStore.locationData.locationId}
          isLoading={UIStore.pageLoading}
        />
      </InfiniteScroll>
    </>
  );
};

export default inject('vendorLocationStore', 'vendorManagementStore')(withStyles(styles)(observer(VendorLocations)));
