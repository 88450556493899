import React, { FC, useMemo } from 'react';
import { useStyles } from './PricingStatus.styles';
import { IBaseActionProps } from '@uplink-shared/custom-ag-grid';
import { Chip } from '@material-ui/core';

interface Props extends IBaseActionProps {
  fieldKey: string;
}

const PricingStatus: FC<Props> = ({ data, fieldKey }) => {
  const classes = useStyles();
  const status = useMemo(() => {
    if (!data) {
      return '';
    }
    return data.status.name;
  }, [ fieldKey ]);

  const _status = status.toLocaleLowerCase();

  return (
    <div className={classes.root}>
      <Chip label={status} variant="outlined" className={_status === 'active' ? 'greenColor' : 'greyColor'} />
    </div>
  );
};

export default PricingStatus;
